import { invoke, events } from '../shared/easyfy.decorators';
import { Idependencies } from '../shared/injectorFactory';
import { element, eventChain } from './../shared/easyfy.core';
import { addCss, removeCss, visible, domLoaded, viewPort, viewWidth, hasClass } from './../shared/easyfy.utils';
import { debounce } from 'ts-debounce';
import { GetPreviewBasket, UpdateBasketProductQty, RemoveProduct } from '../services/basket.service';
import { ProductQty, UpdateType } from './product/productQty';
import { PreviewIcon } from '../components/preview-icon';
import { Localization } from '../common/localization';
import { Ga4 } from '../common/ga4';

@invoke
export class PreviewBasket {
  static Instance: PreviewBasket;
  private locale: Localization;

  private componentHtml: HTMLElement;
  private componentContainer;
  private componentContent: HTMLElement;
  private previewBasketHtml: string;
  private previewIcon: PreviewIcon;

  private productQtyUpdateDebounceTime = 250;
  private productQty: ProductQty;
  private sendingRequest: boolean = false;
  private isSubscriptionBasket: boolean = false;
  private totalProductQty = 0;

  static depends: Array<Idependencies> = [
    {
      func: Localization,
      data: 'js-previewbasket',
    },
  ];

  constructor(private localization: Localization) {
    PreviewBasket.Instance = this;
    this.locale = localization;
    this.productQty = new ProductQty();
    this.previewIcon = PreviewIcon.Instance;
  }

  onInit() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', () => {
      vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    // create preview basket html
    this.componentHtml = this.createPreviewBasketHtml();

    // Append preview basket to body
    const previewbasket: HTMLElement = document.querySelector('.header-actions');
    (previewbasket && visible(previewbasket) && (viewPort().tablet || viewPort().desktop)
      ? previewbasket
      : document.body
    ).appendChild(this.componentHtml);

    // add ref to element
    this.componentContainer = element('.preview-basket');
    this.componentContent = this.componentContainer.querySelector('[data-container="previewbasket-content"]');

    eventChain({
      target: '[data-container="preview-basket"]',
      type: 'click',
      events: [
        { action: 'product-qty-add', callback: this.onProductQtyAdd },
        { action: 'product-qty-subtract', callback: this.onProductQtySubtract },
        { action: 'product-delete', callback: this.onRemoveProduct },
        { event: 'preview-basket-close', callback: this.onPreviewBasketClose },
        { event: 'preview-basket-error-close', callback: this.onPreviewBasketCloseError },
      ],
      options: { capture: true, passive: false },
    });

    eventChain({
      target: '[data-container="preview-basket"]',
      type: 'keyup',
      events: [{ action: 'product-qty-change', callback: this.onProductQuantityChange }],
    });

    GetPreviewBasket('/previewbasket/get', {
      isSubscription: false,
      onlyRows: PreviewBasket.Instance.previewBasketHtml?.length > 0,
    }).then((resp) => {
      this.totalProductQty = resp.data.totalQuantity;
    });

    const vp = viewPort();
    // if (vp.mobile || vp.tablet) {
      if (vp.mobile) {
      const previewbasketContent: HTMLElement = document.querySelector('div.preview-basket');
      if (previewbasketContent) {
        previewbasketContent.style.minWidth = viewWidth() + 'px';
      }
    }
  }

  @events({ target: 'previewbasket', type: 'click', options: { passive: false } })
  onPreviewBasket(e) {
    e.preventDefault();
    const gotocheckout = (e.target as HTMLElement).classList.contains('basket-gotocheckout');
    if (gotocheckout) {
      window.location.href = (e.target as HTMLAnchorElement).href;
      return false;
    }

    if (hasClass(PreviewBasket.Instance.componentContainer, 'open')) {
      PreviewBasket.Instance.onPreviewBasketClose(e);
      return false;
    }

    addCss(PreviewBasket.Instance.componentContainer, 'open');

    if (viewPort().mobile) {
      addCss(document.body, 'previewBasketOpen');
    }

    // Get basket data.
    GetPreviewBasket('/previewbasket/get', {
      isSubscription: false,
      onlyRows: PreviewBasket.Instance.previewBasketHtml?.length > 0,
    }).then((resp) => {
      PreviewBasket.Instance.updateBasketContent(resp?.data?.partialBasket);

      var gaEvent = resp.data?.gaViewCartEvent;
      if (gaEvent) {

        var formattedEvent = {
          event: gaEvent.event,
          ecommerce: gaEvent.ecommerce
        };

        Ga4.Instance.push(formattedEvent);

        if (gaEvent.logToConsole == true) {
          console.log("GA2");
          console.log(formattedEvent);
        }
      }
    });

    return false;
  }

  private ProductQtyUpdate = debounce(() => {
    this.sendingRequest = true;

    UpdateBasketProductQty('/previewbasket/updateProductQty', {
      productId: this.productQty.productId(),
      qty: this.productQty.qty(),
      lineNumber: this.productQty.lineNumber(),
      isSubscription: this.isSubscriptionBasket,
      updatedFrom: 'previewbasket'
    })
      .then((resp) => {
        this.productQty.clearProductQty();
        PreviewBasket.Instance.updateBasketContent(resp?.data?.partialBasket, resp?.data?.message);
        PreviewBasket.Instance.previewIcon.update(resp?.data?.totalQuantity);

        var gaEvent = resp?.data?.gaAddEvent ?? resp?.data?.gaRemoveEvent;

        if (gaEvent) {
          Ga4.Instance.push({
            event: gaEvent.event,
            ecommerce: {
              items: gaEvent.items
            }
          });

          if (gaEvent.logToConsole) {
            console.log("GA2");
            console.log(gaEvent);
          }
        }
      })
      .finally(() => {
        this.sendingRequest = false;
      });
  }, this.productQtyUpdateDebounceTime);

  onProductQtyAdd(e) {
    e.preventDefault();
    if (PreviewBasket.Instance.sendingRequest) return;

    PreviewBasket.Instance.productQty.setProductQty(e.target, UpdateType.Add);
    PreviewBasket.Instance.isSubscriptionBasket = PreviewBasket.Instance.getIsSubscriptionBasket(e.target);

    // Debounce all clicks
    PreviewBasket.Instance.ProductQtyUpdate();

    return false;
  }

  onProductQtySubtract(e) {
    e.preventDefault();
    if (PreviewBasket.Instance.sendingRequest) return;

    PreviewBasket.Instance.productQty.setProductQty(e.target, UpdateType.Subtract);
    PreviewBasket.Instance.isSubscriptionBasket = PreviewBasket.Instance.getIsSubscriptionBasket(e.target);

    // Debounce all clicks
    PreviewBasket.Instance.ProductQtyUpdate();

    return false;
  }

  onProductQuantityChange(e) {
    e.preventDefault();

    if (PreviewBasket.Instance.sendingRequest) return;
    PreviewBasket.Instance.productQty.setProductQty(e.target, UpdateType.Value);
    PreviewBasket.Instance.isSubscriptionBasket = PreviewBasket.Instance.getIsSubscriptionBasket(e.target);

    // Debounce all clicks
    PreviewBasket.Instance.ProductQtyUpdate();
    return false;
  }

  onRemoveProduct(e) {
    e.preventDefault();
    if (PreviewBasket.Instance.sendingRequest) return false;
    let target = e.target;

    if (target.nodeName.toLowerCase() === 'i') {
      target = target.parentNode;
    }
    const productId = target.getAttribute('data-productid');
    if (!productId || productId?.length === 0) return false;
    const lineNumber = target.getAttribute('data-lineNumber');

    PreviewBasket.Instance.isSubscriptionBasket =
      (target as HTMLElement)?.getAttribute('data-subscription')?.toLowerCase() === 'true';

    PreviewBasket.Instance.sendingRequest = true;

    RemoveProduct('/previewbasket/removeProductFromBasket', {
      productId: productId,
      lineNumber: lineNumber,
      isSubscription: PreviewBasket.Instance.isSubscriptionBasket,
    })
      .then((resp) => {
        PreviewBasket.Instance.updateBasketContent(resp?.data?.partialBasket, resp?.data?.message);
        PreviewBasket.Instance.previewIcon.update(resp?.data?.totalQuantity);

        var gaEvent = resp?.data?.gaRemoveEvent;
        if (gaEvent) {

          Ga4.Instance.push({
            event: gaEvent.event,
            ecommerce: {
              items: gaEvent.items
            }
          });

          if (gaEvent.logToConsole) {
            console.log("GA2");
            console.log(gaEvent);
          }
        }
      })
      .finally(() => {
        PreviewBasket.Instance.sendingRequest = false;
      });
    return false;
  }

  onPreviewBasketClose(e) {
    e.preventDefault();
    PreviewBasket.Instance.componentContent.innerHTML = '';
    removeCss(PreviewBasket.Instance.componentContainer, 'open');
    removeCss(document.body, 'previewBasketOpen');
    return false;
  }

  onPreviewBasketCloseError(e) {
    e.preventDefault();

    let errorElement = e.target.closest('.preview-basket-error');
    if (errorElement) {
      errorElement.parentNode.removeChild(errorElement);
    }

    return false;
  }

  updateBasketContent(partialBasket: string, error?: string) {
    try {
      this.componentContent.innerHTML = partialBasket || '';
    } catch (err) {
      console.log('Error', err);
      error = 'Could not get basket, please reload page.';
    }

    if (error) {
      var errorElement = document.createElement('div');
      errorElement.className = 'preview-basket-error';

      var errorMsgElement = document.createElement('div');
      errorMsgElement.innerHTML = error;

      var closeErrorElement = document.createElement('i');
      closeErrorElement.className = 'icon icon-close';
      closeErrorElement.setAttribute('data-event', 'preview-basket-error-close');

      errorElement.append(errorMsgElement);
      errorElement.append(closeErrorElement);

      this.componentContent.prepend(errorElement);
    }
  }

  getIsSubscriptionBasket(target: HTMLElement): boolean {
    return (
      target?.closest('[data-container="product-qty"]')?.getAttribute('data-subscription')?.toLowerCase() === 'true'
    );
  }

  createPreviewBasketHtml() {
    // create html node
    var htmlNode = document.createElement('div');
    htmlNode.className = 'preview-basket';
    htmlNode.setAttribute('data-event', 'close-click-outside');
    htmlNode.appendChild(this.createPreviewBasketHeader());
    return htmlNode;
  }

  createPreviewBasketHeader() {
    // create html node
    var htmlNode = document.createElement('div');
    htmlNode.className = 'preview-basket-container';
    htmlNode.innerHTML = `
      <div class="preview-basket-content" data-container="previewbasket-content"></div>`;
    htmlNode.setAttribute('data-container', 'preview-basket');
    return htmlNode;
    //<i class="icon icon-close" data - event="preview-basket-close" > </i>
    //  < h2 > ${ this.locale.t('header', 'Din Varukorg') } ITEMS IN CART < /h2>
  }
}
