import { hasClass, isDisabled, toggleClass, removeCss, addCss } from '../shared/easyfy.utils';
import { globalEvent, elementsOf, elements } from '../shared/easyfy.core';

export class Dropdown {
  private element: HTMLElement;
  private selectedClassName = 'dd-selected';
  private onChange: Function;
  static Instance: Dropdown;

  constructor(change?: Function) {
    Dropdown.Instance = this;

    this.onChange = change ?? null;

    globalEvent({
      type: 'click',
      events: [
        {
          event: 'dd-toggle',
          callback: this.onToggle,
        },
        {
          event: 'dd-menu',
          callback: this.onOptionClick,
        },
        {
          callback: (e) => {
            const target = e?.target as HTMLElement;
            const inside = target.closest(".dd-quantity-menu") != null;
            const productCardDropDown: NodeListOf<HTMLElement> = Dropdown.Instance.getAllOpenDropdowns();
            if (Dropdown.Instance.isMobile() && !inside && productCardDropDown.length > 0) {
              e.preventDefault();
            }
            productCardDropDown.forEach((el) => {
              if (!el.contains(target)) {
                Dropdown.Instance.onToggle(el);
              }
            });
          },
        },
      ],
    });
  }

  onToggle(e) {
    const parent = e instanceof Element || e instanceof HTMLElement ? e : e?.target?.parentNode;
    var goToCheckoutBtn: HTMLElement = e.target?.closest('.dropdown')?.querySelector('a.go-to-checkout.open');

    if (parent) {
      var slider = parent.closest('.keen-slider__slide') as HTMLElement;
      
      const isVisible: boolean = hasClass(parent, 'open');
      const isdisabled: boolean = isDisabled(parent);

      Dropdown.Instance.hideAll();
      if (Dropdown.Instance.isMobile() && parent?.dataset?.event != "product-card-variant-change" && parent?.dataset?.event != "country-option-change") {
      toggleClass(document.querySelector('body.ismobile') as HTMLElement, 'frozen');
      const isOpen = document.querySelector('.frozen')

        if (isOpen) {
          document.body.style.top = `-${window.scrollY}px`;
          document.body.style.position = 'fixed';
          document.documentElement.style.scrollBehavior = 'auto';
        }
        if (!isOpen) {
          const scrollY = document.body.style.top;
          document.body.style.position = '';
          document.body.style.top = '';
          window.scrollTo(0, parseInt(scrollY || '0') * -1);
          document.documentElement.style.scrollBehavior = null;
        }
      }

      if (slider && !Dropdown.Instance.isMobile() && slider.getBoundingClientRect().width < 300) {
        parent.querySelectorAll('.price>.v-per-stuck').forEach(el => {
          (el as HTMLElement).style.visibility = "hidden";
        })
      }
      if (slider && Dropdown.Instance.isMobile()) {
        toggleClass(slider, 'contents-container');
      }

      if (goToCheckoutBtn) removeCss(goToCheckoutBtn, 'open');

      
      if (isVisible || isdisabled) return false;      
      toggleClass(parent, 'open');
    }
  }

  onOptionClick(e, ref?) {
    e.preventDefault();
    if (!hasClass(e.target, 'dd-item') && !(e.target.parentNode != null && hasClass(e.target.parentNode, 'dd-item'))) {
      return;
    }

    const target = e?.target.closest('.dd-item');
    const targetEl = target as HTMLElement;
    const parent = targetEl?.closest('.dropdown') as HTMLElement;
    const toggle = parent?.querySelector('.dd-toggle') as HTMLElement;
    const menu = parent.querySelector('.dd-quantity-menu') ?? parent.querySelector('.dd-menu');
   
    
    if (parent && toggle) {
      const selectedElement = elementsOf(menu, '.' + Dropdown.Instance.selectedClassName);
      if (selectedElement === targetEl) return;
      removeCss(selectedElement, Dropdown.Instance.selectedClassName);
      addCss(targetEl, Dropdown.Instance.selectedClassName);

      toggle.setAttribute('data-value', targetEl.getAttribute('data-value') ?? '');

      if (parent?.dataset?.event == 'product-card-variant-change') {
        toggle.innerHTML = targetEl?.innerHTML;

        if (Dropdown.Instance.isMobile()) {
          toggle.click()
        }
      }
      
      if (parent?.dataset?.event == 'country-option-change') {
        toggle.innerHTML = targetEl?.innerHTML;
        if (Dropdown.Instance.isMobile()) {
          toggle.click()
        }
      }

      if (Dropdown.Instance.onChange) {
        Dropdown.Instance.onChange.call(target, e, self);
      }

      if (!Dropdown.Instance.isMobile()) {
        toggle.click();
      }

      return false;
    }
  }

  getAllOpenDropdowns = () => elements('.dropdown.open');

  hideAll = () => removeCss(Dropdown.Instance.getAllOpenDropdowns(), 'open');

  isMobile = () => document.querySelector('body.ismobile') != null ? true : false;

}
