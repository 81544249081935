import { invoke } from '../../shared/easyfy.decorators';
import { BaseCompontent } from './../../components/base';
import { PreviewBasket } from '../../components/preview-basket';
import { ProductCard } from '../../components/product-card';
import { Banner } from '../../components/banner'
import { PreviewIcon } from '../../components/preview-icon';
import { globalEvent } from '../../shared/easyfy.core';
import { toggleClass } from '../../shared/easyfy.utils';
import { Klaviyo } from '../../common/klaviyo';
import 'lazysizes';

@invoke
class Global extends BaseCompontent {
  constructor() {
    super();
    Banner;
    PreviewBasket;
    PreviewIcon;
    new ProductCard(true);
    new Klaviyo();
  }

  onInit() {
    globalEvent({
      type: 'click',
      events: [
        {
          event: 'onMenuToggle',
          callback: this.onMenuToggle,
        },
      ], 
    });


    globalEvent({
      type: 'click',
      events: [
        {
          event: 'anchorScroll',
          callback: this.anchorScrollTo
        }
      ]
    });

  }

  onMenuToggle(e: Event) {
    e.preventDefault();
    toggleClass((e.target as HTMLElement).closest('div.menu-item') as HTMLElement, 'active');
    return false;
  }

  // 'Manually' scroll to the internal anchors' targets (TV3-957)
  anchorScrollTo(e: Event): void {
    if (e.target instanceof HTMLAnchorElement && (e.target as HTMLAnchorElement).href.indexOf("#") > -1) {
      e.preventDefault();

      const scrollToElement: HTMLElement = document.querySelector((e.target as HTMLElement).getAttribute('href')) as HTMLElement;
      window.scrollTo({
        top: (scrollToElement.offsetTop - scrollToElement.clientHeight) - (scrollToElement.clientHeight / 2),
        behavior: 'smooth'
      });

      history.pushState("", "", e.target.hash);
    }
  }
}
