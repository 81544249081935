import { invoke, bind } from '../shared/easyfy.decorators';
import { globalEvent } from './../shared/easyfy.core';
import { isEmail } from './../shared/easyfy.utils';
import {
  GetViewedProductResult,
  IViewedProductResponseModel,
  GetStartedCheckoutResult,
  IStartedCheckoutResponseModel,
  IAddedProductToCartResponseModel,
  GetAddedToCartResult,
  IShareBasketResponseModel,
} from '../services/marketing/klaviyo.service';
import { GetQtyFromProductPage, GetQtyFromProductCard, IProductModel } from './product';

declare const window: any;

@invoke
export class Klaviyo {
  static Instance: Klaviyo;
  constructor() {
    Klaviyo.Instance = this;
  }

  onInit() {
    globalEvent({
      type: 'click',
      event: {
        action: 'add-to-basket',
        callback: this.onAddToCart,
      },
    });

    Klaviyo.Instance.onStartedCheckout(document.querySelector('[data-checkout]'));
    Klaviyo.Instance.onViewedProduct(document.querySelector('[data-product-page]'));
    Klaviyo.Instance.onLoggedInAccount(document.querySelector('[data-user-email]'));
  }

  @bind({ target: 'input[type="email"]', type: 'change' })
  onEmailChange(e: Event) {
    e.preventDefault();

    let email: string = <string>$(this).val();
    if (isEmail(email)) {
      var klaviyo = Klaviyo.Instance.getKlaviyoObj();
      klaviyo.push(['identify', { $email: email }]);
    }

    return false;
  }

  onViewedProduct = async (productPage: HTMLDivElement) => {
    if (productPage) {
      let productId = productPage.getAttribute('data-product-page');
      if (productId !== '') {
        let klaviyo = Klaviyo.Instance.getKlaviyoObj();

        let product = {
          Id: productId,
        };

        GetViewedProductResult('/klaviyo/viewedproduct', product)
          .then((resp) => {
            return resp.status == 200 ? <IViewedProductResponseModel>resp?.data : null;
          })
          .then((data) => {
            let result = Klaviyo.Instance.GetViewedProductResult(data);
            if (result) {
              klaviyo.push(['track', 'Viewed Product', result]);
            }
          })
          .catch((err) => { });
      }
    }
  };

  onStartedCheckout = async (checkout: HTMLDivElement) => {
    if (checkout) {
      let basketId = checkout.getAttribute('data-checkout');
      if (basketId !== '') {
        let klaviyo = Klaviyo.Instance.getKlaviyoObj();

        GetStartedCheckoutResult('/klaviyo/startedcheckout', {
          Id: basketId,
        })
          .then((resp) => {
            return resp.status == 200 ? <IStartedCheckoutResponseModel>resp?.data : null;
          })
          .then((data) => {
            let result = Klaviyo.Instance.GetStartedCheckoutResult(data);
            if (result) {
              klaviyo.push(['track', 'Started Checkout', result]);
            }
          })
          .catch((err) => { });
      }
    }
  };

  onLoggedInAccount = async (element: HTMLSpanElement) => {
    if (element) {
      let userEmail = element.getAttribute('data-user-email');
      if (userEmail !== '' && Klaviyo.Instance.isEmail(userEmail)) {
        let klaviyo = Klaviyo.Instance.getKlaviyoObj();
        klaviyo.push(['identify', { $email: userEmail }]);
      }
    }
  };

  GetViewedProductResult(data: IViewedProductResponseModel) {
    let obj = {
      ProductName: data.ProductName,
      ProductID: data.ProductId,
      Categories: data.Categories,
      ImageURL: data.ImageUrl,
      URL: data.Url,
      Brand: data.Brand,
      Price: data.Price,
    };
    return obj;
  }

  GetStartedCheckoutResult(data: IStartedCheckoutResponseModel) {
    let obj = {
      $event_id: data.EventId,
      $value: data.Value,
      ItemNames: data.ItemNames,
      CheckoutURL: data.CheckoutUrl,
      Items: [],
      Categories: data.Categories
    };

    for (var i = 0; i < data.Items.length; i++) {
      let item = data.Items[i];
      let cartItem = {
        ProductID: item.ProductId,
        SKU: item.Sku,
        ProductName: item.ProductName,
        Quantity: item.Quantity,
        ProductURL: item.ProductUrl,
        ImageURL: item.ImageUrl,
        ProductCategories: item.ProductCategories,
      };
      obj.Items.push(cartItem);
    }
    return obj;
  }

  GetShareBasketResult(data: IShareBasketResponseModel) {
    let obj = {
      $event_id: data.EventId,
      $value: data.Value,
      ItemNames: data.ItemNames,
      BasketLink: data.BasketLink,
      Items: [],
    };

    for (var i = 0; i < data.Items.length; i++) {
      let item = data.Items[i];
      let cartItem = {
        ProductID: item.ProductId,
        SKU: item.Sku,
        ProductName: item.ProductName,
        Quantity: item.Quantity,
        ProductURL: item.ProductUrl,
        ImageURL: item.ImageUrl,
        ProductCategories: item.ProductCategories,
      };
      obj.Items.push(cartItem);
    }
    return obj;
  }

  onAddToCart = async (e) => {
    e.preventDefault();

    let klaviyo = Klaviyo.Instance.getKlaviyoObj();
    let productCardModel: IProductModel = null;
    let productPadeModel: IProductModel = null;
    productCardModel = GetQtyFromProductCard(e.target);
    productPadeModel = GetQtyFromProductPage();

    var addedProduct = {
      ProductId: e.target.attributes['data-product']?.value,
      ProductGroupId: e.target.attributes['data-group']?.value,
      Qty: productCardModel.Qty > 0 ? productCardModel.Qty : productPadeModel.Qty || 0,
    };

    return GetAddedToCartResult('/klaviyo/addproducttocart', addedProduct)
      .then((resp) => {
        return resp.status == 200 ? <IAddedProductToCartResponseModel>resp?.data : null;
      })
      .then((data) => {
        let result = Klaviyo.Instance.GetTransformAddedToCartResult(data);
        if (result) {
          klaviyo.push(['track', 'Added to Cart', result]);
        }
      })
      .catch((err) => { });
  };

  GetTransformAddedToCartResult(data: IAddedProductToCartResponseModel) {
    let obj = {
      $value: data.Value,
      AddedItemProductName: data.AddedItemProductName,
      AddedItemProductID: data.AddedItemProductId,
      AddedItemSKU: data.AddedItemSku,
      AddedItemCategories: data.AddedItemCategories,
      AddedItemImageURL: data.AddedItemImageUrl,
      AddedItemURL: data.AddedItemUrl,
      AddedItemPrice: data.AddedItemPrice,
      AddedItemQuantity: data.AddedItemQuantity,
      ItemNames: data.ItemNames,
      CheckoutURL: data.CheckoutUrl,
      Items: [],
    };

    for (var i = 0; i < data.Items.length; i++) {
      let item = data.Items[i];
      let cartItem = {
        ProductID: item.ProductId,
        SKU: item.Sku,
        ProductName: item.ProductName,
        Quantity: item.Quantity,
        ProductURL: item.ProductUrl,
        ImageURL: item.ImageUrl,
        ProductCategories: item.ProductCategories,
      };
      obj.Items.push(cartItem);
    }
    return obj;
  }

  isEmail(email: string) {
    var regexp =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
  }

  getKlaviyoObj() {
    window.klaviyo = window.klaviyo || [];
    return window.klaviyo;
  }
}
