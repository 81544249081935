import axios from '../extensions/axios.extension';

export interface IBasketSummaryModel {
  isSubscription: boolean;
  setNewsletterPreselectedFlag: boolean;
}
export interface IPreviewBasketModel {
  isSubscription: boolean;
  onlyRows: boolean;
}

export interface IUpdateBasketProductQtyModel {
  productId: string;
  qty: number;
  lineNumber: string;
  isSubscription: boolean;
  updatedFrom?: string;
}

export interface IUpdateNewsLetterSubscriptionModel {
  SubscribeToNewsletter: boolean;
}

export interface IRemoveProductModel {
  productId: string;
  lineNumber: string;
  isSubscription: boolean;
}

export interface IBasketModel {
  Basket: any;
  success: boolean;
  message: string;
}

export interface IChangeSubscriptionIntervalModel {
  orderInterval: string;
}
export interface IShareBasketModel {
  basketId: string;
  email: string;
  isSubscription: boolean;
}

export const GetBasketSummary = async (url: string, data: IBasketSummaryModel): Promise<any> => {
  return await axios.get(url, {
    params: {
      isSubscription: data.isSubscription,
      setNewsletterPreselectedFlag: data.setNewsletterPreselectedFlag
    },
  });
};

export const GetPreviewBasket = async (url: string, data: IPreviewBasketModel): Promise<any> => {
  return await axios.get(url, {
    params: {
      isSubscription: data.isSubscription,
      onlyRows: data.onlyRows,
    },
  });
};

export const UpdateBasketProductQty = async (url: string, data: IUpdateBasketProductQtyModel): Promise<any> => {
  return await axios.post(url, <IUpdateBasketProductQtyModel>data);
};

export const UpdateNewsLetterSubscription = async (url: string, data: IUpdateNewsLetterSubscriptionModel): Promise<any> => {
  return await axios.post(url, <IUpdateNewsLetterSubscriptionModel>data);
};

export const RemoveProduct = async (url: string, data: IRemoveProductModel): Promise<any> => {
  return await axios.post(url, <IRemoveProductModel>data);
};

export const ClearBasket = async (url: string): Promise<any> => {
  return await axios.get(url);
};

export const ChangeSubscriptionInterval = async (url: string, data: IChangeSubscriptionIntervalModel): Promise<any> => {
  return await axios.post(url, <IChangeSubscriptionIntervalModel>data);
};

export const ShareBasket = async (url: string, data: IShareBasketModel): Promise<any> => {
  return await axios.post(url, <IShareBasketModel>data);
};
