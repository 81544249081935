import axios from '../extensions/axios.extension';

export interface IAddResourceModel {
  key: string;
  value: string;
}
export interface IAddResourceBatchModel {
  resources: any;
}

export const AddResource = async (url: string, data: IAddResourceModel): Promise<any> => {
  return await axios.post(url, <IAddResourceModel>data);
};

export const AddResourceBatch = async (url: string, data: IAddResourceBatchModel): Promise<any> => {
  return await axios.post(url, <IAddResourceBatchModel>data);
};
