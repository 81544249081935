import { FilterProducts } from '../services/product.service';
import { actions as eventActions, element, elements } from '../shared/easyfy.core';
import { action, actions, event, invoke } from '../shared/easyfy.decorators';
import { toggleShow, scrollToElement, htmlToElement, replaceELement, addCss } from '../shared/easyfy.utils';
import { globalEvent } from '../shared/easyfy.core';
import { log } from 'console';
import { TagManagerSendProductList, tagManagerSettings } from '../tagManager/tagManager';

@invoke
export class Filter {
  static Instance: Filter;
  

  filterMultiSeperator: string = ';';
  filters = {
    group: [],
    page: 0,
  };
  iconToggleClasses = {
    on: 'icon-arrow-right',
    off: 'icon-arrow-down',
  };
  currentFilterGroupSelector: string = null;

  constructor() {
    Filter.Instance = this;
  }


  onInit() {
    this.resetFilter();
    this.readSearchParams();

    // setup range filter
    this.setFilter();

    globalEvent({
      type: 'click',
      events: [
        {
          event: 'filter-mobile-open',
          callback: this.onMobileOpenClick,
        },
        {
          event: 'filter-mobile-close',
          callback: this.onMobileCloseClick,
        },
        {
          event: 'filter-mobile-close',
          callback: this.onMobileCloseClickSection,
        }
      ],
    });


    this.bindEvents();
  }

  onFilterItem(this: HTMLElement) {
    Filter.Instance.currentFilterGroupSelector = this.closest('div').getAttribute('data-container');
    const filterInput: HTMLInputElement = this.querySelector('input');
    filterInput.checked = !filterInput.checked;
    Filter.Instance.refreshData();
    return false;
  }

  onClearFilter(this: HTMLElement, e) {
    const clearButton: HTMLElement = this;
    if (clearButton.classList?.contains('hidden')) {
      return false;
    }
    Filter.Instance.clearFilter(this.attributes['data-filter-key']?.value, this.attributes['data-clear-value']?.value);
    return false;
  }

  onClearAllFilters(this: HTMLElement, e) {
    const clearButton: HTMLElement = this;
    if (clearButton.classList?.contains('hidden')) {
      return false;
    }

    Filter.Instance.resetFilter();
    Filter.Instance.removeAllFilters();
    Filter.Instance.refreshData();

    return false;
  }

  onLoadPage(this: HTMLElement, e: Event) {
    e.preventDefault();
    const pageScrollAttr: boolean = (this.attributes['data-page-scroll']?.value as boolean) || true;
    const pageAppend: boolean = (this.attributes['data-append']?.value as boolean) || false;

    if (this.attributes['data-disabled']?.value) return false;

    Filter.Instance.refreshData(this.attributes['data-page']?.value, pageScrollAttr ?? true, pageAppend, () => {
      if (pageAppend) {
        const parentRow: HTMLElement = this.closest('[data-paging-container]');
        if (parentRow) {
          parentRow.remove();
        }
      }
    });

    return false;
  }

  onToggleFilter(this: Element) {
    const target = this.attributes['data-target']?.value;
    toggleShow(`[data-container="${target}"]`, 'grid');
    const iconElement: Element = this.querySelector('.icon');
    if (iconElement) {
      const isVisible: boolean = iconElement.classList.contains(Filter.Instance.iconToggleClasses.on);
      iconElement.classList.replace(
        isVisible ? Filter.Instance.iconToggleClasses.on : Filter.Instance.iconToggleClasses.off,
        isVisible ? Filter.Instance.iconToggleClasses.off : Filter.Instance.iconToggleClasses.on
      );
    }

    return false;
  }

  onDataSort(this: HTMLSelectElement) {
    const selectedOption = this.options[this.selectedIndex];
    selectedOption.setAttribute('data-active', 'active');
    const options = this.querySelectorAll('[data-active="active"]');
    options.forEach(function (item) {
      if (item != selectedOption) {
        item.removeAttribute('data-active');
      }
    });
    Filter.Instance.refreshData();
  }

  onMobileOpenClick(element: HTMLElement) {
    const filter = document.querySelector<HTMLElement>('[data-placeholder="filter"]');
    if (filter) {
      filter.dataset.open = '1';
      document.querySelector('.filter-container')!.setAttribute('data-event', 'filter-mobile-close');
      document.body.classList.add('noof');
    }
  }
  
  onMobileCloseClick(element: Event) {
    const filter = document.querySelector<HTMLElement>('[data-placeholder="filter"]');
    if (filter && !(element.target instanceof HTMLElement && element.target.classList.contains('filter-content'))) {
      delete filter.dataset.open;
      document.body.classList.remove('noof');
    }
  }
  
  onMobileCloseClickSection(event: Event): void {
    const openElements: NodeListOf<HTMLElement> = document.querySelectorAll('.custom-select-options');
    
    if (openElements.length > 0) {
      openElements.forEach((element: HTMLElement) => {
        element.style.display = "none";
      });
    }
  }

  setFilter() {
    if (this.filters?.group) {
      this.filters?.group.forEach((item) => {
        if (item?.key?.toLowerCase() !== 'a_strength_img' && item?.key?.toLowerCase() !== 'a_intensity_img') {
          if (item.val.includes(';')) {
            item.val.split(';').forEach((value) => {
              this.setElementChecked(item.key.toLowerCase(), value.toLowerCase());
            });
          } else {
            this.setElementChecked(item.key.toLowerCase(), item.val.toLowerCase());
          }
        }
      });
    }
  }

  resetFilter() {
    let filterGroupElement = element('[data-container="category-filter"]');
    if (filterGroupElement) {
      filterGroupElement.querySelectorAll('[type="checkbox"]').forEach((item:HTMLInputElement) => { item.checked = false; });
    }
  }

  clearFilter(filterKey: string, clearValue: string) {
    const el = element(`[data-key-parent="${filterKey.toLowerCase()}"][data-key-value="${clearValue.toLowerCase()}"]`);
    if (el) {
      const checkbox = el.querySelector('input[type=checkbox]');
      if (checkbox) {
        checkbox.checked = false;
        Filter.Instance.refreshData();
      }
    }
  }

  setElementChecked(key: string, value: string) {
    const el = element(`[data-key-parent="${key}"][data-key-value="${value}"]`);
    if (el) {
      const checkbox = el.querySelector('input[type=checkbox]');
      if (checkbox) checkbox.checked = true;
    }
  }

  // rangeFiltersSetup() {
  //   const rangeFilters: NodeListOf<HTMLElement> = elements('[data-range="range_filter"]');
  //   rangeFilters.forEach((item) => {
  //     const step: number = Number.parseInt(item.attributes['data-step']?.value || 5);
  //     const dataKey = item.getAttribute('data-key');
  //     let min: number = Number.parseInt(item.attributes['data-min']?.value || this.noUiSliderOption.min);
  //     let max: number = Number.parseInt(item.attributes['data-max']?.value || this.noUiSliderOption.max);
  //     let filterItem = null;

  //     if (dataKey) {
  //       filterItem = Filter.Instance.filters.group.find((x) => x.key.toLowerCase() === dataKey.toLowerCase());

  //       if (filterItem) {
  //         min = Number.parseInt(filterItem?.min || 0);
  //         max = Number.parseInt(filterItem?.max || 50);
  //       }
  //     }
  //   });

  //   this.noUiSliderUpdate = true;
  // }

  showClearButtonIfFilters() {
    if (location.search.split('&').length == 1) {
      document.querySelector('.clear-filter-mobile')?.classList.add('hide-clear')
    } else {
      document.querySelector('.clear-filter-mobile')?.classList.remove('hide-clear')
    }
  }

  refreshData = (page?: number, scrollToTopBeforeLoad: boolean = false, append?: boolean, callback?: Function) => {
    // refresh page and set all parameters for filter, sortorder, direction and page.
    page = page ? page : 1;

    const filters = [];

    // create filter
    this.createFilterValues(filters, page);

    let url = window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/')) + '/';
    this.showClearButtonIfFilters();
 
    if (location.search) url += location.search;
    FilterProducts(url).then((resp) => {
      if (!resp?.data) {
        // data is empty
        return false;
      }

      // update totalresult
      if (resp?.data?.totalResult) {
        const totalResult: HTMLElement = document.getElementById('totalResult');
        if (totalResult) totalResult.innerHTML = resp?.data?.totalResult;
      }

      // replace productlist
      if (append) {
        element('[data-placeholder="product-list"]')?.insertAdjacentHTML('beforeend', resp?.data?.partialHtml);
      } else {
        element('[data-placeholder="product-list"]').innerHTML = resp?.data?.partialHtml;
      }

      if (tagManagerSettings.gotVersion(2)) {

        // GA
        if (resp.data && resp.data.gaJson) {

          try {
            var pl = element('[data-placeholder="product-list"]');

            // Fix ga -nr (for click events)
            if (resp?.data?.gaTake) {
              var allLists = document.querySelectorAll('[data-placeholder=product-list]');

              for (let i = 0; i < allLists.length; i++) {
                let list = allLists[i];
                list.setAttribute("data-nr", i.toString());

                let products = list.querySelectorAll('[data-container="product-card"]');
                for (let x = 0; x < products.length; x++) {
                  let product = products[x];
                  product.setAttribute("data-nr", ((page == 1 ? resp.data.gaTake - 1 : resp.data.gaTake) * (page - 1) + x).toString());
                }
              }
            }

            // Send GA

            let listId = '';
            let listName = '';
            let placeholder = pl.nextElementSibling;
            while (placeholder) {
              let container = placeholder.getAttribute('data-container');
              if (container == 'product-list-listId') {
                listId = placeholder.getAttribute('data-value');
              } else if (container == 'product-list-listName') {
                listName = placeholder.getAttribute('data-value');
              }

              placeholder = placeholder.nextElementSibling;
            }

            TagManagerSendProductList({
              data: JSON.parse(resp.data.gaJson),
              listId: listId,
              listName: listName
            });
          } catch (ex) {
            console.error('Filter - failed to push ga! ' + ex);
          }
        }
      }

      // replace filters
      const currentFilterGroupElement = element(`[data-container="${Filter.Instance.currentFilterGroupSelector}"`);
      let filterGroupIsOpen = false;
      if (currentFilterGroupElement) {
        filterGroupIsOpen =
          element(`[data-container="${Filter.Instance.currentFilterGroupSelector}"`).style.display == 'block';
      }

      replaceELement(
        element('[data-placeholder="filter"] > .filter-content'),
        htmlToElement(resp.data.partialFilters)?.querySelector('.filter-content')
      );

      // replaceHtml(
      //   element('[data-placeholder="filter"] > .filter-content'),
      //   pFilterHtml?.querySelector('.filter-content') || ''
      // );

      //open selected filter group element if it was open before html-replacement
      if (filterGroupIsOpen) {
        element(`[data-container="${Filter.Instance.currentFilterGroupSelector}"`).style.display = 'block';
      }

      this.bindEvents();

      if (callback) {
        callback.apply(this);
      }
    });

    if (scrollToTopBeforeLoad === true) {
      const headerHeight: number = document.querySelector('header')?.getBoundingClientRect().height || 0;
      const elementTopOffset: number =
        (document.querySelector('[data-placeholder="product-list"]') as HTMLElement)?.offsetTop || 0;

      const offset: number = 20;
      scrollToElement(null, elementTopOffset - headerHeight - offset);
    }

    return false;
  };

  createFilterValues = function (filters: Array<string>, page: number) {
    // add checked filters
    let checked = [];

    elements('div[data-filter="filterGroup"]').forEach((item, index) => {
      let key = item.attributes['data-facets-key']?.value;
      let values = [];

      item.querySelectorAll('input[type="checkbox"]').forEach((item) => {
        if (item.checked) {
          values.push(item.value);
        }
      });

      if (values.length > 0) checked.push(key + ':' + values.join(Filter.Instance.filterMultiSeperator));
    });

    if (checked.length > 0) {
      filters.push('f=' + checked.join('|'));
    }

    // Add SortOrder
    // Get values from clear-filter
    let sortOrderParent: HTMLSelectElement = element('[data-container="product-sort"]');

    if (sortOrderParent) {
      let sortOrder: HTMLOptionElement = sortOrderParent.options[sortOrderParent.options.selectedIndex];

      if (sortOrder && sortOrder.attributes['value'] && sortOrder.attributes['value'] != 'rel')
        filters.push('s=' + sortOrder.attributes['value'].value);

      if (sortOrder) {
        let mobileSortOrder = element('[data-action="product-sort-phone"][data-selected]');
        if (
          mobileSortOrder &&
          mobileSortOrder.attributes['data-sort-value']?.value &&
          mobileSortOrder.attributes['data-sort-value']?.value !== 'rel'
        ) {
          filters.push('s=' + mobileSortOrder.attributes['data-sort-value']?.value);
        }
      }
    }

    // Add SortDirection
    let sortDirectionElement = element('[data-action="direction"]');
    if (sortDirectionElement) {
      let sortDirection = sortDirectionElement.attributes['data-direction']?.value;
      if (sortDirection && sortDirection != 'desc') filters.push('d=' + sortDirection);
    }

    // Add Page
    if (page > 1) {
      filters.push('p=' + page);
    }

    filters.length > 0 ? Filter.Instance.updateQueryStringParam(filters) : Filter.Instance.removeAllFilters();
  };

  readSearchParams() {
    new URLSearchParams(location.search).forEach((val, key) => {
      if (key === 'f') {
        const filters = val.split('|');
        filters.forEach((item) => {
          const values = item.split(':');
          const key = values[0] ?? null;
          const value = values[1] ?? null;
          if (key && value) {
            if (key.toLowerCase() === 'a_strength_img' || key.toLowerCase() === 'a_intensity_img') {
              const rangeValues = value.split('-') ?? [];
              if (rangeValues.length === 2) {
                this.filters.group.push({ key: key, min: rangeValues[0], max: rangeValues[1] });
              }
            } else {
              this.filters.group.push({ key: key, val: value });
            }
          }
        });
      }

      if (key === 'p') {
        this.filters.page = Number.parseInt(val);
      }
    });
  }

  updateQueryStringParam = function (filters: Array<string>) {
    const params = new URLSearchParams(location.search);
    //check if any active filters are present, otherwise remove 'f=' param from URL string.
    if (!filters.find((filter) => filter.includes('f='))) {
      params.delete('f');
    }

    if (!filters.find((filter) => filter.includes('s='))) {
      params.delete('s');
    }

    if (!filters.find((filter) => filter.includes('p='))) {
      params.delete('p');
    }

    filters.forEach((item) => {
      let itemSplit = item.split('=');
      params.set(itemSplit[0], itemSplit[1]);
    });

    window.history.replaceState({}, '', `${location.pathname}?${params}`);
  };

  removeAllFilters = function () {
    const searchParams: URLSearchParams = new URLSearchParams(location.search);
    searchParams.forEach((item, key) => {
      searchParams.delete(key);
    });

    window.history.replaceState({}, '', `${location.pathname}`);
  };

  bindEvents = () => {
    eventActions({
      target: 'load-page',
      type: 'click',
      callback: this.onLoadPage,
      options: { capture: false, passive: false },
    });
    eventActions({ target: 'filter-item', type: 'click', callback: this.onFilterItem, options: { capture: false, passive: false } });
    eventActions({ target: 'clear-filter', type: 'click', callback: this.onClearFilter });
    eventActions({ target: 'clear-all-filters', type: 'click', callback: this.onClearAllFilters });
    eventActions({ target: 'product-sort', type: 'change', callback: this.onDataSort });
  };

  createElementsFromHTML(htmlString: string) {
    var div = document.createElement('div');
    div.innerHTML = htmlString?.trim();
    return div.firstElementChild;
  }
}



