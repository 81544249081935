import { invoke } from "../shared/easyfy.decorators";
import KeenSlider, { KeenSliderInstance, KeenSliderOptions } from 'keen-slider';
import { slider } from "../shared/slider";

@invoke
export class Banner {
  private bannerContainerAttr: string = '[data-bannerslider]';
  constructor() { }

  onInit() {
    slider(this.bannerContainerAttr,
      {
        keenConfig: {
          loop: true
        },
        navigation: true
      },
      true);
  }
}