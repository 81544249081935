import axios from '../extensions/axios.extension';

export interface ISearchModel {
  q: string;
}

export interface ISearchViewModel {
  products: Array<any>;
  brands: Array<any>;
  pages: Array<any>;
  totalHits: number;
  query: string;
  showLowestPriceIn30days: boolean;
}

export const Search = async (url: string, data: ISearchModel): Promise<any> => {
  return await axios.get(url || '/search/autocomplete', {
    params: <object>data,
  });
};

export const GetHighlight = async (url?: string): Promise<any> => {
  return await axios.get(url || '/search/GetHighlight');
};
