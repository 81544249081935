import InjectorFactory, { Idependencies } from './../shared/injectorFactory';
import { globalEvent } from './../shared/easyfy.core';
import Basket from '../common/basket';
import { Dropdown } from './dropdown';
import { parseToBoolean, addCss, removeCss } from '../shared/easyfy.utils';
import { Localization } from '../common/localization';
import { GetQtyFromProductPage, GetQtyFromProductCard, IProductModel } from '../common/product';

export class ProductCard {
  static Instance: ProductCard;

  onAddBefore: Function;
  onAddAfter: Function;
  currentElement: HTMLElement;
  currentHtml: string;
  userIndication: boolean = false;
  intializeDropDown: boolean = true;

  private locale: Localization;
  static depends: Array<Idependencies> = [
    {
      func: Localization,
      data: 'js-productcard',
    },
  ];

  constructor(intializeDropDown: Boolean = true, onAddBefore?: Function, onAddAfter?: Function) {
    ProductCard.Instance = this;
    if (ProductCard.depends) {
      new InjectorFactory(ProductCard.depends, (resolves) => {
        delete ProductCard['depends'];
        this.onInit.apply(this, [...(resolves || []), ...[intializeDropDown, onAddBefore, onAddAfter]]);
      });
    }
  }

  onInit(localization: Localization, intializeDropDown: Boolean, onAddBefore?: Function, onAddAfter?: Function) {
    this.locale = localization;
    if (onAddBefore) this.onAddBefore = onAddBefore;
    if (onAddAfter) this.onAddAfter = onAddAfter;

    globalEvent({
      type: 'click',
      event: {
        action: 'add-to-basket',
        callback: this.onAddToCart,
      },
    });
    if (intializeDropDown) {
      new Dropdown(this.onSelectVariant);
    }
  }

  onAddToCart = async (e) => {
    e.preventDefault();

    if (this.userIndication) return false;

    let eventCancel: Boolean = false;

    // Check if before callback was provided
    if (ProductCard.Instance.onAddBefore) {
      eventCancel = (await ProductCard.Instance.onAddBefore.call(this, e)) || false;
    }

    // Cancel the event
    if (eventCancel) return false;

    ProductCard.Instance.currentElement = e.target;

    let productCardModel: IProductModel = null;
    let productPadeModel: IProductModel = null;

    productCardModel = GetQtyFromProductCard(e.target);
    productPadeModel = GetQtyFromProductPage();

    const productId =
      e.target.attributes['data-product']?.value || e.target.parentElement.attributes['data-product']?.value;
    const elementDataset = e.target.dataset;
    const upsellContainer = e.target.closest('[data-container="upsell"]');
    const paymentprovider: HTMLInputElement = document.querySelector('[id="hidden-paymentprovider"]')
      ?.firstElementChild as HTMLInputElement;

    let isSubScription = false;
    if (e.target.attributes['data-subscription']?.value) {
      isSubScription = e.target.attributes['data-subscription'].value == 'true';
    }

    let addedFrom = 'category';
    if (e.target.attributes['data-added-from']?.value) {
      addedFrom = e.target.attributes['data-added-from'].value;
    }

    let addedFromIndex = 0;
    if (e.target.attributes['data-added-from-index']?.value) {
      addedFromIndex = e.target.attributes['data-added-from-index'].value;
    }

    let data = {
      id: productId || '',
      qty: productCardModel.Qty > 0 ? productCardModel.Qty : productPadeModel.Qty || 0,
      origin: addedFrom || '',
      addedFromIndex: addedFromIndex,
      listType: productCardModel?.ListType?.length > 0 ? productCardModel.ListType : productPadeModel.ListType || '',
      pos: elementDataset?.position || 0,
      isUpSell: parseToBoolean(upsellContainer?.dataset?.isupsell || false),
      isCheckoutBuy: parseToBoolean(upsellContainer?.dataset.checkoutbuy || false),
      paymentMethod: paymentprovider?.dataset?.paymentProvider || '',
      upSellId: elementDataset.upsellId || '',
      promo: parseToBoolean(elementDataset.promo || false),
      hash: elementDataset.hash || '',
      isSubscription: isSubScription,
    };

    this.onSending();
    this.userIndication = true;
    const goToCheckoutBtn: HTMLElement = e?.target?.parentElement?.parentElement?.querySelector('a.go-to-checkout');

    new Basket()
      .addToBasket(data, elementDataset?.productSource || '')
      .then((data) => {
        ProductCard.Instance.onSuccess(() => {
          // Check if before callback was provided
          if (ProductCard.Instance.onAddAfter) {
            ProductCard.Instance.onAddAfter.call(this, data);
          }
          
          if (goToCheckoutBtn) addCss(goToCheckoutBtn, 'open');

        });

        if (document.querySelector('.preview-basket').classList.contains('open')) {
          (document.querySelector('.header-previewbasket') as HTMLElement)?.click();
        }
      })
      .catch(() => {
        ProductCard.Instance.onError();
      });

    return false;
  };

  onSelectVariant = (e) => {
    e.preventDefault();
    const target: HTMLElement = e.target;
    const optionSalesprice = target?.dataset?.salesprice || '';
    const optionRecprice = target?.dataset?.recprice || '';
    const optionLowestprice30days = target?.dataset?.lowestprice30days || '';
    const optionUnitprice = target?.dataset?.unitprice || '';
    const optionFlagValue = target?.dataset?.flagvalue || '';
    // get parent container
    const parentContainer: HTMLElement = target.closest('[data-container="product-card"]');
    const selectedOption = e?.target.closest('.dd-item');
    const selectedOptionEl = selectedOption as HTMLElement;
    const salesprice: HTMLElement = parentContainer.querySelector("[data-container='salesprice']");
    const recprice: HTMLElement = parentContainer.querySelector("[data-container='recprice']");
    const lowestprice30days: HTMLElement = parentContainer.querySelector("[data-container='lowestprice30days']");
    const unitprice: HTMLElement = parentContainer.querySelector("[data-container='unitprice']");
    const unitpriceouter: HTMLElement = parentContainer.querySelector("[data-container='unitpriceouter']");
    const flagContainer: HTMLElement = parentContainer.querySelector("[data-container='flag-container']");
    const campaignFlag: HTMLElement = parentContainer.querySelector("[data-container='campaign-flag-value']");
    const campaignpercentFlag: HTMLElement = parentContainer.querySelector("[data-container='campaignpercent-flag-value']");
    
    
    const goToCheckoutBtn: HTMLElement = target.closest('.dropdown').querySelector('a.go-to-checkout.open');
    

    // update price
    if (salesprice) salesprice.innerText = optionSalesprice;
    if (unitprice) unitprice.innerText = optionUnitprice ?? '';
    if (recprice) recprice.innerText = optionRecprice && Number.parseFloat(optionRecprice.replace(" kr", "")) > Number.parseFloat(optionSalesprice.replace(" kr", "")) ? optionRecprice : '';
    if (lowestprice30days) lowestprice30days.innerText = optionLowestprice30days && Number.parseFloat(optionLowestprice30days.replace(" kr", "")) > Number.parseFloat(optionSalesprice.replace(" kr", "")) ? optionLowestprice30days : '';
    if (unitpriceouter) unitpriceouter.style.visibility = optionUnitprice ? '' : 'hidden';

    //update campaignflag value
    if (campaignFlag || campaignpercentFlag) {
      if (Number.parseInt(optionFlagValue) <= 0) {
        flagContainer.classList.add('hidden');
      } else {
        if (campaignFlag) campaignFlag.innerText = '-' + optionFlagValue + '%' ?? '';
        if (campaignpercentFlag) campaignpercentFlag.innerText = '-' + optionFlagValue + '%' ?? '';
        flagContainer.classList.remove('hidden');
      }
    }

    ProductCard.Instance.changeProductCardQtyInfo(selectedOptionEl, parentContainer);
    if (goToCheckoutBtn) removeCss(goToCheckoutBtn, 'open');


    return false;
  };

  changeProductCardQtyInfo = (targetEl: HTMLElement, parentEl: HTMLElement) => {
    let packPrice = ProductCard.Instance.getElBySelector(parentEl, ".pack-price");
    if (packPrice) packPrice.innerText = ProductCard.Instance.getInnerText(targetEl, ".v-pack-price");

    let packSize = ProductCard.Instance.getElBySelector(parentEl, ".pack-size");
    if (packSize) packSize.innerText = ProductCard.Instance.getInnerText(targetEl, ".v-quantity");

    let unitPrice = ProductCard.Instance.getElBySelector(parentEl, ".unit-price");
    if (unitPrice) {
      unitPrice.innerText = ProductCard.Instance.getInnerText(targetEl, ".v-per-stuck");

      const unitPriceTextSibling: ChildNode = unitPrice.nextSibling;
      if (unitPriceTextSibling) {
        unitPriceTextSibling.remove();
      }
    }
  };

  getElBySelector = (el: HTMLElement, selector: string): HTMLElement => {
    return el.querySelector(selector);
  }

  getInnerText = (el: HTMLElement, selector: string): string => {
    let elHtml = ProductCard.Instance.getElBySelector(el, selector);
    return elHtml != null ? elHtml.textContent.trim() : "";
  }

  onSending() {
    const addingTxt = document.querySelector('.adding-text').innerHTML;
    ProductCard.Instance.currentHtml = ProductCard.Instance.currentElement.innerHTML;
    this.onBtnChange(this.locale.t(addingTxt, addingTxt), 'load', false);
  }

  onSuccess(cb: Function = null) {
    const addedTxt = document.querySelector('.added-text').innerHTML;
    this.onBtnChange(this.locale.t(addedTxt, addedTxt), 'icon icon-check icon-white', true, IconAction.Replace);
    ProductCard.Instance.onReset();
    if (cb) cb.call(this);
  }

  onError() {
    this.onBtnChange(this.locale.t('Error', 'Error'), 'icon icon-error icon-white', true, IconAction.Replace);
    ProductCard.Instance.onReset();
  }

  onReset(sleep: number = 1000, cb: Function = null) {
    if (ProductCard.Instance.currentElement) {
      window.setTimeout(() => {
        ProductCard.Instance.currentElement.innerHTML = ProductCard?.Instance?.currentHtml || '';
        ProductCard.Instance.userIndication = false;
        if (cb) cb.call(this);
      }, sleep);
    }
  }

  onBtnChange(text: string, icon: string, removeLoad: boolean, iconAction: IconAction = IconAction.Add) {
    let iconEl = ProductCard.Instance.currentElement.querySelector('.icon');
    const textEl = ProductCard.Instance.currentElement.querySelector('label');

    if (iconEl) {
      if (removeLoad) {
        iconEl.classList.remove('load');
      }

      if (iconAction == IconAction.Add) {
        iconEl.classList.add(icon);
      }

      if (iconAction == IconAction.Replace) {
        iconEl.classList.value = icon;
      }
    }

    if (textEl) {
      textEl.textContent = text || '';
    }
  }
}

enum IconAction {
  Add,
  Replace,
}
