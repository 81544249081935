import { isString, isCheckout } from './../shared/easyfy.utils';
import { Ga4 } from './ga4';
import {
  AddToBasket,
  IAddToBasketResponseModel,
  IAddToBasketPostModel,
  AddBasketTagManager,
} from '../services/product.service';
import { TagManagerAddToBasket } from '../tagManager/tagManager';
import { PreviewIcon } from '../components/preview-icon';
import easyfyAlerter, { MessageSeverity } from '../components/alerter';

declare var fbq: any;
export default class Basket {
  private previewIcon: PreviewIcon;
  alerter: easyfyAlerter;

  constructor() {
    this.previewIcon = PreviewIcon.Instance;
    this.alerter = new easyfyAlerter();
  }

  addToBasket(basketPostModel: IAddToBasketPostModel, source: string): Promise<any> {
    return AddToBasket('/basket/add', basketPostModel)
      .then((resp) => {
        return resp.status == 200 ? <IAddToBasketResponseModel>resp?.data : null;
      })
      .then((data) => {

        if (data?.gaAddEvent) {

          Ga4.Instance.push({
            event: data?.gaAddEvent.event,
            ecommerce: {
              items: data?.gaAddEvent.items
            }
          });
          if (data.gaAddEvent.logToConsole == true) {
            console.log("GA2");
            console.log(data.gaAddEvent);
          }
        }

        if (data?.errorMessages?.length > 0) {
          if (isString(data?.errorMessages)) {
            this.alerter.alert(MessageSeverity.error, data?.errorMessages as string);
          }

          if (Array.isArray(data?.errorMessages)) {
            this.alerter.alert(MessageSeverity.error, data?.errorMessages.join('<br />'));
          }

          return Promise.reject(
            new Error(
              Array.isArray(data?.errorMessages) ? data?.errorMessages.join(' ') : (data?.errorMessages as string)
            )
          );
        }

        // Update minibasket
        if ((data.ordinaryBasket || data.subscriptionBasket) && !isCheckout()) {
          var quantity = (data.ordinaryBasket?.totalQuantity ?? 0) + (data.subscriptionBasket?.totalQuantity ?? 0);
          this.previewIcon.update(quantity);
        }

        // Move this to separate file
        if (typeof fbq != 'undefined' && fbq != null) {
          var productId = data.fbPixel != null ? data.fbPixel.sku : null;
          if (productId) {
            fbq('track', 'AddToCart', {
              content_ids: ['' + productId + ''],
              content_type: 'product',
              currency: data.fbPixel.currency,
            });
          }
        }

        return data;
      })
      .then((data) => {
        let tm = data?.tagManager ? <AddBasketTagManager>data?.tagManager : null;

        if (tm) {
          TagManagerAddToBasket({
            id: tm.productId,
            name: tm.name,
            price: tm.price,
            brand: tm.brand,
            qty: tm.qty,
            analyticsCategory: tm.analyticsCategory,
            source: source,
          });
        }

        return data;
      });
  }
}
