import { invoke, bind } from '../shared/easyfy.decorators';

declare const window: any;

@invoke
export class Ga4 {
  static Instance: Ga4;
  constructor() {
    Ga4.Instance = this;
  }

  push = function (payload: any) {

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.

      if (typeof payload === 'string' || payload instanceof String) {
        window.dataLayer.push(JSON.parse(payload as string));
      } else {
        window.dataLayer.push(payload);
      }
  }

  logRequest = function (payload) {

    if (typeof payload === 'string' || payload instanceof String) {

    } else {
      payload = JSON.stringify(payload);
    }

    return fetch("/ga4/logRequest", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: payload
    });
  }


  pushAndLog = function (payload) {
    this.push(payload);
    this.logRequest(payload);
  }

};