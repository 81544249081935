export interface IProductModel {
  Qty: number;
  ListType: string;
}

// get qty from product-card
export const GetQtyFromProductCard = (target) => {
  const container: HTMLElement = target.closest('[data-container="product-card"]');

  if (container == null) return { Qty: 0, ListType: '' };

  const dropdownElement = container.querySelector("[data-container='variant-selected']");
  let selectedOption = dropdownElement?.querySelector('.dd-item.dd-selected');
  if (selectedOption == null) {
    selectedOption = dropdownElement;
  }

  return {
    Qty: Number.parseFloat(selectedOption.getAttribute('data-value')) || 0,
    ListType: container?.dataset?.listtype || '',
  };
};

// get qty from product-page
export const GetQtyFromProductPage = () => {
  const container: HTMLElement = document.querySelector('[data-container="product-page"]');

  let variants = container?.querySelector('[data-container="variants"]');
  let selectedOption = variants?.querySelector('.dd-item.dd-selected');
  if (selectedOption == null) {
    return { Qty: 0, ListType: '' };
  }

  return {
    Qty: Number.parseFloat(selectedOption.getAttribute('data-value')) || 0,
    ListType: container?.dataset?.listtype || '',
  };
};
