import { invoke, actions, event } from '../shared/easyfy.decorators';
import { elementsOf } from '../../shared/easyfy.core';

interface IProductQty {
  productId: string;
  qty: number;
  lineNumber: string;
}

export enum UpdateType {
  Add,
  Subtract,
  Value,
}

export class ProductQty {
  private productQty: IProductQty;
  private qtyContainerStr: string = '[data-container="product-qty"]';
  private qtyElementStr: string = '[data-container="product-qty-val"]';

  public productId = () => this.productQty.productId;
  public qty = () => this.productQty.qty;
  public lineNumber = () => this.productQty.lineNumber;

  public setProductQty(element: HTMLElement, qtyType: UpdateType) {
    const qtyContainer: HTMLElement = element.closest(this.qtyContainerStr);
    const qtyElement: HTMLInputElement | HTMLElement = elementsOf(qtyContainer, this.qtyElementStr);
    if (qtyContainer && qtyElement) {
      const productId = qtyContainer.getAttribute('data-productid') || '';
      if (!this.hasProduct(productId)) this.setProduct({ productId: productId, qty: this.getQtyValue(qtyElement), lineNumber: "" });
      this.setQty(qtyType, qtyElement);
      const lineNumber = qtyContainer.getAttribute('data-lineNumber') || '';
      if (lineNumber) this.setLineNumber(lineNumber);
    } else {
      throw new Error('qtyContainer or qtyElement does not exists..');
    }
  }

  public clearProductQty() {
    this.productQty = null;
  }

  private hasProduct(productId: string) {
    return this.productQty && this.productQty?.productId === productId;
  }

  private setProduct(productQty: IProductQty) {
    this.productQty = { productId: productQty.productId, qty: productQty.qty, lineNumber: "" };
  }

  private setLineNumber(productLineNumber: string) {
    this.productQty.lineNumber = productLineNumber;
  }

  private getQtyValue(qtyElement: HTMLInputElement | HTMLElement) {
    return Number.parseInt(
      qtyElement instanceof HTMLInputElement ? qtyElement?.value : qtyElement.getAttribute(this.qtyElementStr)
    );
  }

  private setQtyValue(qtyElement: HTMLInputElement | HTMLElement) {
    if (qtyElement instanceof HTMLInputElement) {
      (<HTMLInputElement>qtyElement).value = this.productQty.qty.toString();
    }
  }

  private setQty(qtyType: UpdateType, qtyElement: HTMLInputElement | HTMLElement) {
    if (qtyType === UpdateType.Add) {
      this.productQty.qty += 1;
      this.setQtyValue(qtyElement);
    }
    if (qtyType === UpdateType.Subtract) {
      this.productQty.qty -= 1;
      this.setQtyValue(qtyElement);
    }
    if (qtyType === UpdateType.Value) {
      let qtyVal: number = this.getQtyValue(qtyElement) || 1;
      if (qtyVal === 0) qtyVal = 1;
      this.productQty.qty = qtyVal;
    }
  }
}
