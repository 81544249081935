import { globalEvent } from '../shared/easyfy.core';
import { Id } from '../shared/easyfy.utils';

export default class Alerter {
  timeBeforeRemoved: number = 4000;
  static stos: Array<Object> = [];

  constructor(timeBeforeRemoved?: number) {
    if (timeBeforeRemoved) this.timeBeforeRemoved = timeBeforeRemoved;

    globalEvent({
      type: 'click',
      event: {
        event: 'remove-alerter',
        callback: this.onAlertRemoveClick.bind(this),
      },
    });
  }

  public alert(severity: MessageSeverity, message: string, element?: HTMLElement, timeBeforeRemoved?: number) {
    const pkId = `alert-${Id(8)}`;
    const html = `<div data-id="${pkId}" class="alerter ${element ? `` : `alerter-fixed`} ${MessageSeverity[severity]}">
                  <span>${message}</span>
                  <a href="#" data-event="remove-alerter" class="m-l-small">
                    <i data-alertId="${pkId}" class="icon icon-close icon-grey"></i>
                  </a>
                </div>`;

    if (element) {
      element.innerHTML = html;
    } else {
      let content = document.createElement('div');
      content.innerHTML = html;
      document.body.appendChild(content);
    }

    Alerter.stos.push({
      pkId: pkId,
      sto: setTimeout(
        pkId => {
          // remove element
          this.removeAlertElement(pkId);

          // empty and remove timeout
          this.findAndRemoveSto(pkId);
          
        },
        timeBeforeRemoved || this.timeBeforeRemoved || 2000,
        pkId
      ),
    });
    return false;
  }

  private onAlertRemoveClick(e: Event) {
    e.preventDefault();
    const pkId = (e.target as HTMLElement).dataset.alertid;
    if (pkId) {
      this.removeAlertElement(pkId);
      this.findAndRemoveSto(pkId);
    }
    return false;
  }

  private removeAlertElement(pkId: string) {
    const alertElement = document.querySelector(`[data-id="${pkId}"]`);
    if (alertElement) alertElement.remove();
  }

  private findAndRemoveSto(pkId: string){
    let itemIndex: number; 
    const currentSto: Object = Alerter.stos.filter((item, index) => {
      if(item['pkId'] === pkId){
        itemIndex = index;
        return item;
      }
    });

    if(currentSto){
      try{
        clearTimeout(currentSto['sto']);
        Alerter.stos = Alerter.stos.slice(itemIndex, itemIndex);
      }
      catch(err){}
    }
  }
}

export enum MessageSeverity {
  success,
  warning,
  error,
}
