import i18next, { i18n } from 'i18next';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import HttpApi from 'i18next-http-backend';
import { IAddResourceModel, AddResourceBatch } from '../services/localization.service';
import { debounce } from 'ts-debounce';

export class Localization {
  static Instance: Localization;

  loaded: boolean = false;

  keyPrefix: string = '';
  stringToResourceMap: Array<IAddResourceModel> = [];
  hasNewResources: boolean = false;
  debounceTime: number = 1000;
  i18nextInstance: i18n;

  constructor(keyPrefix: string) {
    Localization.Instance = this;

    if (keyPrefix?.length <= 0) return;
    this.keyPrefix = keyPrefix;

    this.i18nextInstance = i18next.createInstance();
    this.i18nextInstance.use(Backend).init(
      {
        debug: false,
        lng: document?.body?.dataset?.culture?.substr(0, 2) ?? 'en',
        fallbackLng: false,
        fallbackNS: false,
        interpolation: {
          escapeValue: false,
        },
        backend: {
          backends: [
            LocalStorageBackend, // primary
            HttpApi, // fallback
          ],
          backendOptions: [
            {
              expirationTime: 0, // 7*24*60*60*1000
            },
            {
              loadPath:
                location.protocol +
                '//' +
                location.host +
                (function () {
                  var regex = /^\/[a-z]{2}(\/|$)/g;
                  var found = location.pathname.match(regex);
                  if (found?.length > 0) {
                    return found[0].length === 3 ? found[0] : found[0].substr(0, 3);
                  }
                  return '';
                })() +
                '/resources/' +
                keyPrefix +
                '/',
            },
          ],
        },
      },
      (err, t) => {
        if (err) return console.log('something went wrong loading', err);
        this.loaded = true;
      }
    );
  }

  keyTransform = (key: string, usePrefix: boolean = true) =>
    (usePrefix ? `${this.keyPrefix}.${key}` : key).replace(/[.]/g, '-').toLowerCase();

  t(key: string, fallbackText: string, usePrefix: boolean = true): string {
    key = this.keyTransform(key, usePrefix);

    if (!this.i18nextInstance.exists(key)) {
      this.stringToResourceMap.push({ key: key, value: fallbackText });
    }

    if (this.stringToResourceMap.length > 0) {
      this.addResourceBatch();
    }

    let response = this.i18nextInstance.t(key);
    return response === key ? fallbackText : response;
  }

  addResourceBatch = debounce(() => {
    try {
      AddResourceBatch('/resourcesbatch/', { resources: this.stringToResourceMap });
    } catch (err) {}
  }, this.debounceTime);
}
