import { element } from '../shared/easyfy.core';
import { scrollToTop, showAll, hideAll, debounce } from '../shared/easyfy.utils';
import { event } from '../shared/easyfy.decorators';

export default class ScrollButton {
  scrollButton: HTMLElement;
  docMaxScroll: number;
  docScrollTop: number;
  docPosition: number;
  debounceTime = 100;

  constructor() {
    this.scrollButton = element('.scroll-button');
    this.docMaxScroll = document.documentElement.scrollHeight - document.documentElement.clientHeight;

    window.addEventListener('scroll', this.toggleScrollButton, false);
    window.dispatchEvent(new CustomEvent('scroll'));
  }

  toggleScrollButton= debounce((e) => {
    this.docScrollTop = document.documentElement.scrollTop;
    this.docPosition = Math.floor((this.docScrollTop / this.docMaxScroll) * 100);
    if (!this.scrollButton) this.scrollButton = element('.scroll-button');
    if (this.scrollButton) this.docPosition > 50 ? showAll([this.scrollButton], 'flex') : hideAll([this.scrollButton]);
  }, this.debounceTime);

  @event({ target: 'scroll-top', type: 'click' })
  onScrollTop() {
    scrollToTop();
  }
}
