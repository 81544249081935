import { element } from "../shared/easyfy.core";

export interface IAddToBasketTagMangerModel {
  id: string;
  source: string;
  name: string;
  price: number;
  brand: string;
  qty: string | number | string[];
  analyticsCategory: string;
}

export interface IProductListTagManagerModel {
  data: any;
  listId: string;
  listName: string;
}

declare global {
  interface Window { dataLayer: any; }
}

export interface ITagManagerSettings {
  internalVersions: Array<Number>;
}
class TagManagerSettings implements ITagManagerSettings {
  internalVersions: Array<Number>;

  constructor() {

    let body = element('body');
    if (body) {
      let internalVersionsStr = body.getAttribute('data-ga-internal-version');
      if (internalVersionsStr) {
        this.internalVersions = internalVersionsStr.split(',').map(n => parseInt(n));
      }
    }
  }

  gotVersion(version: Number) {
    return this.internalVersions.includes(version);
  }
}
export const tagManagerSettings = new TagManagerSettings();

export const TagManagerAddToBasket = (data: IAddToBasketTagMangerModel) => {
  let ids = data.id.split('/');
  let id = ids[ids.length - 1];

  (<any>window).dataLayer?.push({
    event: 'addToCart',
    ecommerce: {
      add: {
        // 'add' actionFieldObject measures.
        actionField: { list: data.source },
        products: [
          {
            //  adding a product to a shopping cart.
            name: data.name,
            id: id,
            price: data.price,
            brand: data.brand,
            category: data.analyticsCategory,
            variant: '""',
            quantity: data.qty,
          },
        ],
      },
    },
  });
};

export const TagManagerSendProductList = (data: IProductListTagManagerModel) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.

  try {
    for (let i = 0; i < data.data.ecommerce.items.length; i++) {
      if (!data.data.ecommerce.items[i].item_list_id) {
        data.data.ecommerce.items[i].item_list_id = data.listId;
      }
      if (!data.data.ecommerce.items[i].item_list_name) {
        data.data.ecommerce.items[i].item_list_name = data.listName;
      }
    }

    window.dataLayer.push(data.data);

    console.log(data.data);
  } catch {
    console.error("Failed to send ga-product list");
  }
};