import axios from '../../extensions/axios.extension';

export interface IAddedProductToCartModel {
  ProductId: string;
  ProductGroupId: string;
  Qty: number;
}

export interface IStartedCheckoutModel {
  Id: string;
  BasketUrl?: string;
}

export interface IShareBasketModel {
  Id: string;
  Email: string;
  BasketUrl?: string;
}

export interface IViewedProductModel {
  Id: string;
}

export interface IAddedProductToCartResponseModel {
  Value: number;
  AddedItemProductName: string;
  AddedItemProductId: string;
  AddedItemSku: string;
  AddedItemCategories?: Array<string>;
  AddedItemImageUrl: string;
  AddedItemUrl: string;
  AddedItemPrice: number;
  AddedItemQuantity: number;
  ItemNames?: Array<string>;
  CheckoutUrl: string;
  Items?: Array<ICartItem>;
}

export interface IStartedCheckoutResponseModel {
  EventId: string;
  Value: number;
  ItemNames?: Array<string>;
  CheckoutUrl: string;
  Categories?: Array<string>;
  Items?: Array<ICartItem>;
}

export interface IShareBasketResponseModel {
  EventId: string;
  Value: number;
  ItemNames?: Array<string>;
  BasketLink: string;
  Categories?: Array<string>;
  Items?: Array<ICartItem>;
}

export interface IViewedProductResponseModel {
  ProductName: string;
  ProductId: string;
  ImageUrl: string;
  Brand: string;
  Price: number;
  Url: string;
  CheckoutUrl: string;
  Categories?: Array<string>;
}

export interface ICartItem {
  ProductId: string;
  Sku: string;
  ProductName: string;
  Quantity: number;
  ItemPrice: number;
  RowTotal: number;
  ProductUrl: string;
  ImageUrl: string;
  ProductCategories?: Array<string>;
}

export const GetAddedToCartResult = async (url: string, data: IAddedProductToCartModel): Promise<any> => {
  return await axios.post(url, <IAddedProductToCartModel>data);
};

export const GetStartedCheckoutResult = async (url: string, data: IStartedCheckoutModel): Promise<any> => {
  return await axios.post(url, <IStartedCheckoutModel>data);
};

export const GetShareBasketResult = async (url: string, data: IShareBasketModel): Promise<any> => {
  return await axios.post(url, <IShareBasketModel>data);
};

export const GetViewedProductResult = async (url: string, data: IViewedProductModel): Promise<any> => {
  return await axios.post(url, <IViewedProductModel>data);
};
