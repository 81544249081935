import { invoke, event } from '../shared/easyfy.decorators';
import { bind as bindEvent, elements } from '../shared/easyfy.core';
import { Idependencies } from '../shared/injectorFactory';
import { toCurrency, show, hide, winHeight, detector, diffMinutes, addCss, removeCss } from '../shared/easyfy.utils';
import { element, offAll, actions, globalEvent } from '../shared/easyfy.core';
import { ISearchViewModel, Search, GetHighlight } from '../services/search.service';
import Basket from '../common/basket';
import { IAddToBasketPostModel } from '../services/product.service';
import { Localization } from '../common/localization';
import { debounce } from 'ts-debounce';
import { TagManagerSendProductList, tagManagerSettings } from '../tagManager/tagManager';
import { Ga4 } from '../common/ga4';
import { Filter } from './filter';

@invoke
export class MainSearch {
  static Instance: MainSearch;
  private locale: Localization;

  private searchBarElement: Element | HTMLElement;
  private searchInput: HTMLInputElement;
  private searchResults: HTMLElement;
  private searchForm: HTMLFormElement;
  private searchClear: HTMLElement;
  private searchIcon: HTMLButtonElement;

  private lastSearchQuery: string;
  private lastSearchTotalHits: number;
  private lastSearchResult: ISearchViewModel;

  private highlight: ISearchViewModel;
  private highlightQuery: string;
  private highlightTotalHits: number;
  private searchUrl: string;

  private thresholdVolume: number;
  private onSearchDebounceTime: number = 500;

  private headerElement: HTMLElement;

  static EasyfyDataLayer: Array<any> = [];

  static depends: Array<Idependencies> = [
    {
      func: Localization,
      data: 'js-search',
    },
  ];

  constructor(private localization: Localization) {
    MainSearch.Instance = this;
    this.locale = localization;
    this.thresholdVolume = 1;
    this.SetSearchUrl();
    Filter;
  }

  onInit() {
    this.searchBarElement = element('[data-container="search-bar"]');
    this.searchInput = document.querySelector('#search-input');
    this.searchResults = document.querySelector('[data-container="main-search-results"]');
    this.searchForm = this.searchBarElement.querySelector('form.search-form');
    this.searchClear = this.searchBarElement.querySelector('[data-event="search-clear"]');
    // this.headerElement = document.querySelector('.header-container');
    this.headerElement = document.querySelector('header');
    this.searchIcon = this.searchBarElement.querySelector('form.search-form button');

    globalEvent({ type: 'click', event: { callback: this.HideSearchResult } });
    globalEvent({ type: 'focusin', event: { callback: this.SearchBarInFocus } });

    bindEvent({ target: this.searchInput, type: 'focusin focusout keyup', callback: this.onSearch });

    bindEvent({
      target: this.searchIcon,
      type: 'click',
      callback: (e) => {
        if (detector.mobile() && elements('.dropdown.open').length == 0) {
          this.searchForm.submit();
        }
      },
    });

    globalEvent({
      type: 'click',
      event: {
        callback: (e) => {

          const clickedElement = e.target;
          const dataHolderElement = clickedElement.closest("[data-gtm-product-id]");
          if (!dataHolderElement) return false;

          var productId = dataHolderElement.attributes["data-gtm-product-id"]?.value ?? null;
          var index = dataHolderElement.attributes["data-gtm-index"]?.value ?? 1;

          var gtmItem = MainSearch.EasyfyDataLayer[productId];
          if (gtmItem) {
            gtmItem.index = index;
            delete MainSearch.EasyfyDataLayer[productId];
            var select_item = {
              event: "select_item",
              ecommerce: {
                items: [
                  gtmItem
                ]
              }
            };

            Ga4.Instance.push(select_item);
          }
        }
      }
    });
      

    // prevent form to submit
    this.searchForm.addEventListener(
      'submit',
      (e) => {
        e.stopPropagation();
        return false;
      },
      true
    );

    window.addEventListener(
      'resize',
      debounce((e) => {
        this.SearchWindowResize();
        return false;
      }, 50)
    );
  }

  private onSearch = debounce((e) => {
    if (!MainSearch.Instance.IsEventTargetInsideSearchInput(e)) return false;

    if (e.type === 'focusin' || e.type === 'keyup') {
      const value: string = MainSearch.Instance.searchInput.value.trim() ?? '';

      if (value && value?.length >= MainSearch.Instance.thresholdVolume && MainSearch.Instance.searchUrl) {
        MainSearch.Instance.unBindEvents();
        
        // search
        if (value === MainSearch.Instance.lastSearchQuery) {
          if (MainSearch.Instance.searchResults?.innerHTML?.length <= 0) {
            MainSearch.Instance.SearchRender(MainSearch.Instance.lastSearchResult);
          }

          return false;
        }

        // show search clear
        MainSearch.Instance.ShowSearchClear();
        MainSearch.Instance.ActivateSearchButton();
        Search(MainSearch.Instance.searchUrl, { q: value?.trim() }).then((resp) => {
          if (resp?.status === 200) {
            MainSearch.Instance.lastSearchQuery = value;
            MainSearch.Instance.lastSearchTotalHits = resp?.data?.totalHits ?? 0;
            MainSearch.Instance.lastSearchResult = resp?.data as ISearchViewModel;
            MainSearch.Instance.SearchRender(MainSearch.Instance.lastSearchResult);

            if (tagManagerSettings.gotVersion(2)) {

              // Send GA
              if (resp.data && resp.data.gaJson) {

                try {
                  let listId = '';
                  let listName = '';

                  let pl = element('[data-placeholder="product-list"]');
                  if (pl) { // Product list page
                    let placeholder = pl.nextElementSibling;
                    while (placeholder) {
                      let container = placeholder.getAttribute('data-container');
                      if (container == 'product-list-listId') {
                        listId = placeholder.getAttribute('data-value');
                      } else if (container == 'product-list-listName') {
                        listName = placeholder.getAttribute('data-value');
                      }

                      placeholder = placeholder.nextElementSibling;
                    }
                  } else { // Auto search
                    let searchContainer = element('[data-container="main-search-results"]');

                    if (searchContainer) {

                      let allSearchProducts = searchContainer.querySelectorAll('[data-container="product-card"]');
                      if (allSearchProducts.length > 0) {

                        let gaJson = JSON.parse(resp.data.gaJson);

                        for (let x = 0; x < allSearchProducts.length; x++) {
                          let product = allSearchProducts[x];
                          product.setAttribute("data-nr", x);
                          product.setAttribute("data-list-name", gaJson.ecommerce.items[0].item_list_name);
                          product.setAttribute("data-list-id", gaJson.ecommerce.items[0].item_list_id);
                        }

                        for (let x = 0; x < gaJson.ecommerce.items?.length ?? 0; x++) {
                          let item = gaJson.ecommerce.items[x];
                          MainSearch.EasyfyDataLayer[item.item_id] = item;
                        }
                      }
                    }
                  }

                  TagManagerSendProductList({
                    data: JSON.parse(resp.data.gaJson),
                    listId: listId,
                    listName: listName
                  });
                } catch (ex) {
                  console.error('Search failed to push ga! ' + ex);
                }
              }
            }
          }
        });
      } else {
        MainSearch.Instance.SearchClear();
        MainSearch.Instance.DeactivateSearchButton();

        if (MainSearch.Instance.highlight) {
          // show cache search highlight
          MainSearch.Instance.lastSearchQuery = MainSearch.Instance.highlightQuery;
          MainSearch.Instance.lastSearchTotalHits = MainSearch.Instance.highlightTotalHits;

          if (MainSearch.Instance.highlight?.totalHits > 0) {
            MainSearch.Instance.SearchRender(MainSearch.Instance.highlight);
          }
        } else {
          // get search highlight
          GetHighlight().then((resp) => {
            if (resp?.status === 200) {
              MainSearch.Instance.highlightQuery = MainSearch.Instance.lastSearchQuery = resp?.data?.query ?? '';
              MainSearch.Instance.highlightTotalHits = MainSearch.Instance.lastSearchTotalHits =
                resp?.data?.totalHits ?? 0;
              MainSearch.Instance.highlight = resp?.data as ISearchViewModel;

              if (MainSearch.Instance.highlight?.totalHits > 0) {
                MainSearch.Instance.SearchRender(MainSearch.Instance.highlight);
              }
            }
          });
        }
      }
    }

    if (e.type === 'focusout') {
      if (!MainSearch.Instance.IsEventTargetInsideSearch(e)) hide(this.searchResults);
    }
  }, this.onSearchDebounceTime);

  @event({ target: 'search-clear', type: 'click' })
  onSearchClear() {
    MainSearch.Instance.SearchClear();
  }

  //toggle header icon to search bar on product page
  @event({ target: 'toggle-search-bar', type: 'click' })
  onToggleSearch() {
    addCss(document.getElementById("product-page-search"), "hidden");
    removeCss(document.getElementById("search-bar"), "responsive-search");
  }

  SetSearchUrl() {
    let pathnames: Array<string> = location?.pathname?.split('/');
    let tenant: string;
    if (pathnames.length >= 2) {
      tenant = pathnames[1];
      this.searchUrl = `/${tenant}/search/autocomplete`;
    }
  }

  SearchClear() {
    MainSearch.Instance.searchInput.value = '';
    MainSearch.Instance.HideSearchResult(null);
    MainSearch.Instance.HideSearchClear();
  }

  SearchBarInFocus(e) {
    if (e.type === 'focusin') {
      if (!MainSearch.Instance.IsEventTargetInsideSearch(e)) hide(MainSearch.Instance.searchResults);
    }
  }

  SearchRender(data: ISearchViewModel) {
    let searchHtml = '';
    let refContentHtml = '';

    // Search summery
    searchHtml += MainSearch.Instance.CreateSearchHeader();

    if (data.products) {
      let index = 1;
      searchHtml += `<div class="products-container">
          ${MainSearch.Instance.CreateSearchCategory(MainSearch.Instance.locale.t('AutoComplete.Products', 'Products'))}
          ${this.mapData(data.products, (x) => MainSearch.Instance.CreateSearchProductCard(x, x?.label, index++))}
        </div>`;
    }

    if (data.brands) {
      refContentHtml += `<div class="brands-container">
          ${MainSearch.Instance.CreateSearchCategory(MainSearch.Instance.locale.t('AutoComplete.Brands', 'Brands'))}
          ${this.mapData(data.brands, (x) => MainSearch.Instance.CreateSearchLink(x?.href, x?.label, x?.src))}
        </div>`;
    }

    if (data.pages) {
      refContentHtml += `<div class="pages-container">
          ${MainSearch.Instance.CreateSearchCategory(MainSearch.Instance.locale.t('AutoComplete.Pages', 'Pages'))}
          ${this.mapData(data.pages, (x) => MainSearch.Instance.CreateSearchLink(x?.href, x?.label, x?.src))}
        </div>`;
    }

    if (refContentHtml.length > 0) {
      searchHtml += `<div class="content-right">${refContentHtml}</div>`;
    }

    if (searchHtml) {
      MainSearch.Instance.searchResults.innerHTML = searchHtml;
      MainSearch.Instance.BindEvents();

      document.body.classList.add('no-overflow');
      show(this.searchResults, 'grid');

      if (detector.mobile()) {
        this.searchResults.style.overflow = 'auto';
      }
      this.SearchWindowResize();
    } else {
      hide(this.searchResults);
    }
  }

  HideSearchResult(e: any) {
    if (e?.target) {
      if (!MainSearch.Instance.IsEventTargetInsideSearch(e)) {
        MainSearch.Instance.HideSearch();
      }
    } else {
      MainSearch.Instance.HideSearch();
    }

    // e?.target ? (!MainSearch.Instance.IsEventTargetInsideSearch(e) ? hide(MainSearch.Instance.searchResults) : '') : hide(MainSearch.Instance.searchResults);
    // document.body.classList.remove('no-overflow');
  }

  private HideSearch() {
    document.body.classList.remove('no-overflow');
    hide(MainSearch.Instance.searchResults);
  }

  IsEventTargetInsideSearchInput(e: any) {
    return this.searchInput.contains(e.target);
  }

  IsEventTargetInsideSearch(e: any) {
    return this.searchBarElement.contains(e.target);
  }

  CreateSearchHeader = () => `<div class="search-header">
        <div class="search-suggestion">
            <span>${MainSearch.Instance.lastSearchTotalHits ?? 0}</span>
            hits on <span>"${MainSearch.Instance.lastSearchQuery ?? ''}"</span>
        </div>
        <a href="${MainSearch.Instance.SearchPath()}" alt="Show all">Show all</a>
    </div>`;

  CreateSearchCategory = (val: string) =>
    val.includes('searchpromotedproducts')
      ? `<div class="category search-promoted-products">${val}</div>`
      : `<div class="category">${val?.substr(val.indexOf('/') + 1, val.length)}</div>`;

  CreateSearchProductCard(data: any, match: string, index: any): string {
    if (!data) return '';

    let searchProductClass = 'product grid columns-medium-2';
    if (data.isOutOfStock) {
      searchProductClass += 'outofstock';
    }

    let gtm = '';
    if (data.gaProductId) {
      gtm += ` data-gtm-product-id="${data.gaProductId}" data-gtm-index="${index}"`;
    }

    let inlineProductInfo = '';
    let rowPrice = '';

    inlineProductInfo = MainSearch.Instance.CreateProductInformation(data.label, data?.price, data?.productFlag, data?.showLowestPriceIn30days)

    return `<div class="${searchProductClass}" data-container="product-card"${gtm}>
        <a class="product-link grid grid-small columns-medium-2" href="${data?.href}">
          <div>
            ${MainSearch.Instance.CreateProductImage(data?.src, data?.label)}
          </div>
          ${inlineProductInfo}
        </a>
        ${rowPrice}
        ${MainSearch.Instance.CreateProductBuySection(data)}
      </div>`;
  }

  CreateProductImage = (src, alt) => `<img src="${src}" alt="${alt}">`;

  CreateProductInformation = (label?: string, price?: string, productFlag?, showLowestPriceIn30days?: boolean) => {
    let labelContent = '';
    let priceContent = '';
    let productFlagContent = ''

    if (label) {
      labelContent = `<label>${label}</label>`;
    }

    if (price) {
      priceContent = `<div data-container="salesprice" class="prices text-size-4">${price}</div>`;
    }

    if (productFlag !== null && productFlag?.flagTypeAsString !== 'NotSet') {

      let flagTypeLower = productFlag?.flagTypeAsString?.toLowerCase();
      let flagInner = ``;// `<span resource-key="ProductPage.Flags.${productFlag?.flagTypeAsString}">${productFlag?.alternativeProductFlag}</span>`;

      if (productFlag?.flagTypeAsString === 'BlackFriday') {
        flagInner = `<span data-container="${flagTypeLower}-flag-value">-${productFlag?.blackFridayFlag}%</span>`;
      }
      else if (productFlag?.overrideDiscountPercent > 0) {
        flagInner = `<span data-container="${flagTypeLower}-flag-value">-${productFlag?.overrideDiscountPercent}%</span>`;
      }
      else if (showLowestPriceIn30days && productFlag?.calculateDiscountPercent > 0 && (productFlag?.flagTypeAsString === 'Campaign' || productFlag?.flagTypeAsString === 'CampaignPercent')) {
        flagInner += `<span data-container="${flagTypeLower}-flag-value">-${productFlag?.calculateDiscountPercent}%</span>`;
      }
      else if (productFlag?.discountPercent > 0 && productFlag?.flagTypeAsString === 'CampaignPercent') {
        flagInner = `<span data-container="${flagTypeLower}-flag-value">-${productFlag?.discountPercent}%</span>`;
      }
      else {
        flagInner += `<span data-container="${flagTypeLower}-flag-value"></span>`;
        flagTypeLower += ` hidden`;
      }

      if (flagTypeLower !== null && flagTypeLower !== undefined) {
        productFlagContent = `<span data-container="flag-container" class="flag ${flagTypeLower}">${flagInner}</span>`;
      }
    }

    return `<div class="price-container"><div class="product-search-label">${labelContent}${productFlagContent}</div>${priceContent}</div>`;
  };

  CreateProductBuySection(data) {
    const bracketPriceVariants: Array<any> = data?.getLowestBracketPrices;
    const basePriceVariants: Array<any> = data?.getBasePrices;
    const historyPrices: Array<any> = data?.getHistoryPrices;

    const variantSelect: string = MainSearch.Instance.CreateProductVariants(
      bracketPriceVariants,
      basePriceVariants,
      historyPrices,
      data?.highestPrice,
      data?.defaultMinQty,
      data?.isOutOfStock,
      data?.overrideDiscountPercent,
      data?.OverrideDiscountBlackFriday,
      data?.lowestPriceIn30days,
      data?.showLowestPriceIn30days
    );

    const buyButton: string = MainSearch.Instance.CreateProductBuyBtn(data, variantSelect);

    if (variantSelect && buyButton && !data?.isOutOfStock) {
      return `<div class="product-actions">${variantSelect}${buyButton}</div>`;
    }

    if (buyButton) {
      if (detector.mobile() && data?.getLowestBracketPrices && data?.getLowestBracketPrices.length <= 0) {
        return `<div class="product-actions">${MainSearch.Instance.CreateProductInformation(
          data?.price
        )}${buyButton}</div>`;
      }

      return `<div class="product-actions">
        ${MainSearch.Instance.CreateProductInformation('')}
        ${buyButton}</div>`;
    }

    return '';
  }

  CreateProductVariants(
    variants: Array<any>,
    basePriceVariants: Array<any>,
    historyPrices: Array<any>,
    highestPrice: any,
    defaultMinQty: any,
    isOutOfStock: boolean,
    overrideDiscountPercent: string,
    overrideDiscountBlackFriday: string,
    lowestPriceIn30days: any,
    showLowestPriceIn30days: boolean
  ): string {
    let options: string = '';

    if (!variants) return '';

    let selectedItem = '';
    variants.forEach((item, index) => {
      const price = item.minQty * item.outPriceIncVat;
      const priceToCurrency = toCurrency(price, item.outPriceCurrency);
      let flagPercent: number = 0;
      //get base price to compare
      const basePrice = basePriceVariants.find(x => x.minQty == (item.minQty == 1 ? 0 : item.minQty));
      lowestPriceIn30days = historyPrices?.find(x => x.minQty == (item.minQty == 1 ? 0 : item.minQty));
      lowestPriceIn30days = lowestPriceIn30days && lowestPriceIn30days?.outPriceIncVat != 0 && lowestPriceIn30days?.outPriceIncVat < basePrice?.outPriceIncVat
        ? lowestPriceIn30days?.outPriceIncVat
        : basePrice?.outPriceIncVat;

      if (overrideDiscountBlackFriday?.length > 0) {
        flagPercent = Number.parseFloat(overrideDiscountBlackFriday);
      } else {
        if (!showLowestPriceIn30days) {
          flagPercent =
            overrideDiscountPercent > overrideDiscountPercent
              ? highestPrice <= 0
                ? 0
                : Math.round(100 - 100 * (item.outPriceIncVat / highestPrice))
              : 0;
        } else {
          flagPercent =
            basePrice?.outPriceIncVat <= 0 && lowestPriceIn30days <= 0
              ? Math.round(100 - 100 * (item.outPriceIncVat / highestPrice))
              : Math.round(100 - 100 * (item.outPriceIncVat / lowestPriceIn30days));
        }
      }

      if (item.flagType == 'NoFlag') {
        flagPercent = 0;
      }

      const percentMarkup = flagPercent > 0 ? `<span class="clearOnSelect"> (-${flagPercent}%)</span>` : ``;

      if (item.minQty === defaultMinQty) {
        selectedItem = `<div class="dd-selected dd-toggle" data-value="${item.minQty}" data-event="dd-toggle">${item.minQty}-pack ${priceToCurrency}</div>`;
      }
      options += `<div class="dd-item" data-value="${item.minQty}" data-salesprice="${priceToCurrency}" data-qty="${item.minQty}" data-flagvalue="${flagPercent}">${item.minQty}-pack ${priceToCurrency}${percentMarkup}</div>`;
    });

    let disableDropdown: string = '';
    if (isOutOfStock) {
      disableDropdown = 'disabled="disabled"';
    }

    return options
      ? `<div class="dropdown dd-center" data-event="product-card-variant-change" ${disableDropdown}>${selectedItem}<div class="dd-menu" data-event="dd-menu">${options}</div></div>`
      : '';
  }

  CreateProductBuyBtn(data, variantSelect: string): string {
    if (data?.isOutOfStock) {
      return `<a class="button button-primary" disabled="disabled">${MainSearch.Instance.locale.t(
        'AutoComplete.OutOfStock',
        'Out of stock'
      )}</a>`;
    }

    const buyBtnStr =
      detector.mobile() && !detector.tablet()
        ? MainSearch.Instance.locale.t('AutoComplete.AddToBag.Mobile', 'Buy')
        : MainSearch.Instance.locale.t('AutoComplete.AddToBag', 'Add to bag');
    return `<a href="#" class="button button-primary" data-action="add-to-cart-search" data-id="${data?.id}">
        <span class="bag-btn-text">${buyBtnStr}</span>
        <i class="icon icon-bag-add"></i>
      </a>`;
  }

  CreateSearchLink(href: string, label: string, imageSrc?: string) {
    if (!href || !label) return '';
    const imageHtml: string = imageSrc ? `<img class="m-r-small" src="${imageSrc}" />` : '';
    // return `<a class="search-link" href="${href}">${imageHtml}${label}</a>`;
    return `<a class="search-link" href="${href}">${label}</a>`;
  }

  SearchWindowResize() {
    if (MainSearch.Instance.searchResults) {
      // MainSearch.Instance.searchResults.style.maxHeight = winHeight() - (MainSearch?.Instance?.searchResults?.offsetTop + 100) + 'px';
      // detector.mobile() ? 60 : 40)
      let heightOffset = 40;

      if (detector.mobile()) {
        const previewbasket = document.querySelector('.header-previewbasket') as HTMLElement;
        heightOffset = previewbasket.style.display != 'none' ? previewbasket.offsetHeight + 10 : 20;
      }

      MainSearch.Instance.searchResults.style.maxHeight =
        winHeight() - (MainSearch.Instance.headerElement.offsetHeight + heightOffset) + 'px';
      MainSearch.Instance.searchResults.style.top =
        document.querySelector('header').getBoundingClientRect().height.toString() + 'px';
    }
  }

  SearchPath = () =>
    (MainSearch.Instance.searchForm.getAttribute('action') + '?q=' ?? '/') + MainSearch.Instance.lastSearchQuery ?? '';

  ShowSearchClear = () => (MainSearch.Instance.searchClear.style.display = 'flex');
  HideSearchClear = () => (MainSearch.Instance.searchClear.style.display = 'none');
  ActivateSearchButton = () => (MainSearch.Instance.searchIcon.disabled = false)
  DeactivateSearchButton = () => (MainSearch.Instance.searchIcon.disabled = true)

  BindEvents() {
    actions({
      target: 'add-to-cart-search',
      type: 'click',
      callback: MainSearch.Instance.onSearchAddToBasket,
      options: { passive: false },
    });
    window.addEventListener('scroll', this.SearchWindowResize, { passive: true });
  }

  unBindEvents() {
    offAll('add-to-cart-search', 'click', MainSearch.Instance.onSearchAddToBasket);
    window.removeEventListener('resize', MainSearch.Instance.SearchWindowResize);
  }

  onSearchAddToBasket(this: HTMLElement, e: Event) {
    e.preventDefault();
    const productCardContainer = this.closest('[data-container="product-card"]');
    const selectElement: HTMLSelectElement = productCardContainer.querySelector('.dropdown');

    let selectedOptionValue: number = 1;
    if (selectElement != null) {
      const selectedOption: HTMLSelectElement = selectElement.querySelector('.dd-selected');
      selectedOptionValue = Number.parseInt(selectedOption?.dataset.value || '0');
    }

    const data: IAddToBasketPostModel = {
      id: this.dataset.id || '',
      qty: selectedOptionValue,
      origin: 'category' || '',
      listType: '',
      pos: 0,
      isSubscription: false,
      addedFrom: 'search'
    };

    // Add product to basket
    new Basket().addToBasket(data, '').then(() => {});

    return false;
  }

  mapData = (items: Array<any>, action: Function) => {
    if (!items || !action) return '';

    let data: string = '';
    for (let item of items) {
      data += action.call(this, item);
    }
    return data;
  };
}
