import { invoke, bind } from '../shared/easyfy.decorators';
import ScrollButton from '../components/scroll-button';
import { MainSearch } from '../components/search';
import Toggle from '../components/toggle';

@invoke
export class BaseCompontent {
  private scrollButton: ScrollButton;
  private easyfySearch: any;
  private toggle: Toggle;

  constructor() {}

  onInit() {
    this.scrollButton = new ScrollButton();
    this.toggle = new Toggle();
    this.easyfySearch = MainSearch;
  }

  @bind({ target: 'a[href="#"]:not([data-action]):not([data-event])', type: 'click' })
  onPreventEmptyAnchorToTrigger(e) {
    e.preventDefault();
    return false;
  }
}
