import { globalEvent, elements } from '../shared/easyfy.core';
import { visible, hideAll, showAll, toggleClass, removeCss, SetCookie, GetCookie } from '../shared/easyfy.utils';

export default class Toggle {
  static Instance: Toggle;

  constructor() {
    Toggle.Instance = this;

    globalEvent({
      type: 'click',
      events: [
        {
          callback: (e) => {
            Toggle.Instance.hideToggle(e?.target);
          },
        },
        {
          event: 'toggle',
          callback: this.onToggleElement,
        },
        {
          event: 'close-click-outside',
          callback: this.closeMenuIfClickOutside,
        },
        {
          event: 'toggle-content',
          callback: this.toggleContent,
        },
        {
          event: 'show-reviews',
          callback: this.showReviews,
        }
      ],
    });
  }

  onToggleElement(e: any, hide: boolean) {
    if (e?.preventDefault) e.preventDefault();
    if (e.target) e = e.target;

    let target = e.attributes['data-target']?.value;
    if (!target) {
      e = e.parentNode as HTMLElement;
      target = e.attributes['data-target']?.value;
    }

    if (!target) return false;

    const el = document.querySelectorAll<HTMLElement>(`[data-container="${target}"]`);
    const displayType: string = (e.attributes['data-displaytype']?.value as string) ?? 'block';

    const replaceText: string = e.attributes['data-replaceText']?.value;
    const toggleClassName: string = e.attributes['data-toggleClassName']?.value;
    const toggleClassTarget: string = e.attributes['data-toggleClassTarget']?.value;

    if (!hide) {
      Toggle.Instance.hideToggle(e);
    }

    if (replaceText) {
      const currentContent = e.textContent.trim();
      if (!e.attributes['data-originalText']) e.setAttribute('data-originalText', currentContent);

      e.innerHTML = e.innerHTML.replace(
        currentContent,
        currentContent === replaceText ? e.attributes['data-originalText']?.value || '' : replaceText
      );
    }

    if (toggleClassName && toggleClassTarget) {
      const toggleClassTargetEl: HTMLElement = document.querySelector(toggleClassTarget);
      if (toggleClassTargetEl) toggleClass(toggleClassTargetEl, toggleClassName);
    }

    el.forEach((e) => {
      if (target !== 'nav-menu-content' && !toggleClassName && !toggleClassTarget)
        visible(e) ? hideAll([e]) : showAll([e], displayType);
      if (toggleClassName && !toggleClassTarget) {
        hide && visible(e) ? removeCss(e, toggleClassName) : toggleClass(e, toggleClassName);
      }

      if (target === 'search-bar') e?.querySelector('input')?.focus();
      if (target === 'nav-menu-content') Toggle.Instance.AsideMenu(e);
    });

    return false;
  }


  closeMenuIfClickOutside(): void {
    const menu: HTMLElement = document.querySelector('.aside-menu')
    const previewBasket: HTMLElement = document.querySelector('.preview-basket')

    menu.classList.remove('open')
    previewBasket.classList.remove('open')
    document.body.classList.remove('previewBasketOpen')
  }

  hideToggle(target: HTMLElement) {
    const toggleElements: NodeListOf<HTMLElement> = elements('[data-event="toggle"]');
    toggleElements.forEach((el) => {
      const dataTarget = el.attributes['data-target']?.value;
      if (dataTarget === 'nav-menu-content') return;

      const dataTargetElement: NodeListOf<HTMLElement> = document.querySelectorAll<any>(
        `[data-container="${dataTarget}"]`
      );

      dataTargetElement.forEach((item) => {
        if (!el.contains(target) && visible(item) && visible(el) && !item.contains(target) && el !== target) {
          this.onToggleElement(el, true);
        }
      });
    });
  }

  AsideMenu(e) {
    SetCookie('menuOpen', visible(e).toString(), 30);
  }

  toggleContent(e: Event): void {
    const element = <HTMLElement>e?.srcElement;
    element?.classList?.contains('open') ? element?.classList?.remove('open') : element?.classList?.add('open');
  }

  showReviews(e: Event): void {
    const reviews = document.getElementById('reviews');
    
    const yOffset = -(document.querySelector('header')?.clientHeight);
    const y = reviews?.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
    reviews?.classList?.contains('open') ? '' : reviews?.classList?.add('open')
  }
}
