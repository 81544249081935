import axios from '../extensions/axios.extension';

export interface IAddToBasketPostModel {
  id: string;
  qty: number;
  origin: string;
  pos: number;
  listType: string;
  addons?: Array<string>;
  view?: string;
  configAddonId?: string;
  crossSellId?: string;
  crossSellLineNumber?: number;
  isUpSell?: boolean;
  isCheckoutBuy?: boolean;
  paymentMethod?: string;
  upSellId?: string;
  promo?: boolean;
  hash?: string;
  isSubscription: boolean;
  addedFrom?: string;
}

export interface IAddToBasketResponseModel {
  ordinaryBasket: Basket;
  subscriptionBasket: Basket;
  errorMessages: Array<string> | string;
  upSellModel: any;
  crossSellModel: any;
  tagManager: AddBasketTagManager;
  fbPixel: AddBasketFbPixel;
  isCheckoutBuy: boolean;
  partialBasket: any;
  partialUpSell: any;
  paymentMethod?: string;
  gaAddEvent?: GaEvent,
  gaRemoveEvent?: GaEvent
}

export interface Basket {
  totalPrice: string;
  totalQuantity: number;
}

export interface AddBasketTagManager {
  productId?: string;
  name?: string;
  price?: number;
  brand?: string;
  qty?: number;
  analyticsCategory?: string;
}

export interface AddBasketFbPixel {
  sku?: string;
  currency?: string;
}

export interface GaEvent {
  event: string;
  items: any;
  logToConsole?: boolean
}


export const AddToBasket = async (url: string, data: IAddToBasketPostModel): Promise<any> => {
  return await axios.post(url, <IAddToBasketPostModel>data);
};

export const UpdateProductQty = async (url: string, data: IAddToBasketPostModel): Promise<any> => {
  return await axios.post(url, <IAddToBasketPostModel>data);
};

export const RemoveProduct = async (url: string, data: IAddToBasketPostModel): Promise<any> => {
  return await axios.post(url, <IAddToBasketPostModel>data);
};

export const FilterProducts = async (url: string): Promise<any> => {
  return await axios.get(url);
};
