import { invoke } from '../shared/easyfy.decorators';
import { elements, one } from './../shared/easyfy.core';
import { addCss, removeCss, show, hide, viewPort, isCheckout } from './../shared/easyfy.utils';
import { getQuantity } from './../services/quantity.service';

interface storeQty {
  qty: number;
  url: string;
}

@invoke
export class PreviewIcon {
  static Instance: PreviewIcon;
  private iconElements: NodeListOf<HTMLElement>;
  constructor() {
    PreviewIcon.Instance = this;
  }

  onInit() {
    this.iconElements = elements('[data-container="minibasket"]');
    // if quantity is null get qty from server
    this.getQuantity();
  }

  getQuantity() {
    if (isCheckout()) return false;

    getQuantity('/basket/getQuantity').then((resp) => {
      if (resp?.data?.qty || resp?.data?.subscriptionQty) {
        var quantity = (resp?.data?.qty ?? 0) + (resp?.data?.subscriptionQty ?? 0);
        this.update(quantity, false);
      }
    });
  }

  update(totalQuantity: number, animate: boolean = true, storeQty: boolean = true) {
    // Update basket text
    if (this.iconElements) {
      const vp = viewPort();
      this.iconElements.forEach((iconElement) => {
        // only mobile/tablet. show or hide basket icon
        if (vp.mobile || vp.tablet) {
          //if (totalQuantity > 0) {
            document.body.dataset.basket = '1';
            //show(iconElement, 'grid');
          //} else {
          //  hide(iconElement);
          //  delete document.body.dataset.basket;
          //}
        }

        const spanElement: HTMLSpanElement = iconElement.querySelector('span.previewBasket-totalQty');
        if (spanElement) {
          totalQuantity === 0 ? spanElement.remove() : (spanElement.textContent = totalQuantity.toString() ?? '0');
        } else {
          const basketIconElement: HTMLElement = iconElement.querySelector('.basket-icon');
          if (basketIconElement && totalQuantity > 0) {
            const appendType: string = basketIconElement.dataset.appendtype || 'inside'; // inside or append or appendto

            if (appendType === 'inside') {
              basketIconElement.innerHTML = `<span class="previewBasket-totalQty">${totalQuantity ?? 0}</span>`;
            }

            if (appendType === 'append') {
              let el = document.createElement('span');
              el.classList.add('previewBasket-totalQty');
              el.innerHTML = `${totalQuantity ?? 0}`;
              basketIconElement.append(el);
            }

            if (appendType === 'appendTo') {
              const appendTo = basketIconElement.dataset.appendto || ''; // query selector
              if (appendTo && appendTo.length > 0) {
                let el = document.createElement('span');
                el.classList.add('previewBasket-totalQty');
                el.innerHTML = `${totalQuantity ?? 0}`;
                basketIconElement.querySelector(appendTo).append(el);
              }
            }
          }
        }

        if (animate) {
          this.animateElement(iconElement.querySelector('i.icon-cart'));
        }
      });
    }
  }

  getStoreQty(): storeQty {
    let lsQty = localStorage.getItem('qty');
    if (lsQty) return JSON.parse(lsQty) as storeQty;
  }

  setStoreQty(storeQty: storeQty) {
    localStorage.setItem('qty', JSON.stringify(storeQty));
  }

  removeStoreQty() {
    localStorage.removeItem('qty');
  }

  animateElement = (el: HTMLElement) => {
    if (el && el?.style?.display != 'none') {
      removeCss(el, ['bounce', 'animate-bounce']);
      addCss(el, ['bounce', 'animate-bounce']);

      one(el, 'animationend oAnimationEnd animationend webkitAnimationEnd', () => {
        removeCss(el, ['bounce', 'animate-bounce']);
      });
    }
  };
}
